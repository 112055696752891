const LEGACY_USER_ID_CUTOFF_DATE = '2022-03-12';

/*
 * We used to send user.username as our unique third party service ID (amplitude, sentry, etc)
 * but going forward that value may not be consistent for the lifetime of the user since we may
 * start allowing users to choose a username (and potentially change it). Our userID uuid value is
 * guaranteed to never change for the lifetime of a user record so it's a better choice for these
 * services.
 *
 * We choose an arbitrary date for swapping over so that users already ID'd in other services
 * don't result in duplicated user records.
 */
export function getUserIDForThirdParties(user: {
  username: string;
  userID: string;
  createdAt: Date | string;
}): string {
  const createdAtStr =
    typeof user.createdAt === 'string' ? user.createdAt : user.createdAt.toISOString();
  return createdAtStr < LEGACY_USER_ID_CUTOFF_DATE ? user.username : user.userID;
}
