import { Image } from 'react-native';

import { AuthScreenContainer } from '@oui/app-core/src/components/AuthScreenContainer';
import { Button } from '@oui/app-core/src/components/Button';
import { Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useCurrentUser } from '@oui/app-core/src/hooks/useCurrentUser';
import { logEvent } from '@oui/app-core/src/lib/log';
import { OnboardingVariant } from '@oui/lib/src/types/graphql.generated';

import { StackScreenProps } from '@src/types/navigation';

export function Confidentiality(props: StackScreenProps<'Confidentiality'>) {
  const { data } = useCurrentUser();
  return (
    <AuthScreenContainer heading={null} testID="Confidentiality">
      <View style={{ alignItems: 'center' }}>
        <Image source={require('../assets/Shield.png')} style={{ height: 91, width: 72 }} />
      </View>
      <Text
        text="Confidential"
        size={28}
        weight="bold"
        style={{ marginTop: 20, marginBottom: 10, textAlign: 'center' }}
      />
      <Text
        text={`Everything shared in this app is confidential.

We will not share anything with others unless you give specific permission.`}
        size={17}
        weight="semibold"
        style={{ alignSelf: 'center', maxWidth: 300, textAlign: 'center' }}
      />
      <Button
        testID="Confidentiality_continueButton"
        text="Got it"
        style={{ alignSelf: 'center', marginTop: 20 }}
        disabled={!data}
        onPress={() => {
          logEvent('acknowledge_confidentiality');
          if (data?.user?.role?.productStatic) {
            props.navigation.navigate('homeStatic', { screen: 'Learn' });
          } else if (
            data?.user?.role?.productConfig &&
            'onboardingVariant' in data?.user?.role?.productConfig &&
            data?.user?.role?.productConfig?.onboardingVariant === OnboardingVariant.COLLABORATIVE
          ) {
            props.navigation.navigate('ControlledMyStoryMyPlan');
          } else {
            props.navigation.navigate('home', { screen: 'Home' });
          }
        }}
      />
    </AuthScreenContainer>
  );
}
