import { useNavigation } from '@react-navigation/native';
import { useState } from 'react';
import { useWindowDimensions } from 'react-native';

import { Button } from '@oui/app-core/src/components/Button';
import { Heading } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { flatCard, useTheme } from '@oui/app-core/src/styles';
import { formatGQLDate } from '@oui/lib/src/gqlDate';
import { FragmentOf, graphql, readFragment } from '@oui/lib/src/graphql/tada';
import { ProductVariant } from '@oui/lib/src/types/graphql.generated';

import { TabScreenProps } from '@src/types';

export const QuickActionsFragment = graphql(`
  fragment QuickActions on Role {
    progress {
      content
      completed
    }
    product {
      ID
      slug
    }
  }
`);

/**
 * Exposes quick actions for various widgets/diaries
 */
export function QuickActions({
  data: _data,
}: {
  data: FragmentOf<typeof QuickActionsFragment> | null | undefined;
}) {
  const { theme } = useTheme();
  const { fontScale } = useWindowDimensions();
  const { navigate } = useNavigation<TabScreenProps<'Home'>['navigation']>();
  const data = readFragment(QuickActionsFragment, _data);
  const [today] = useState(formatGQLDate);
  const { $t } = useI18n();

  const isActivityPlanningLocked = !data?.progress.find((p) => p.content === 'ACTIVITY_PLANNING')
    ?.completed;
  const isSleepDiaryLocked = !data?.progress.find((p) => p.content === 'SLEEP')?.completed;
  const isSpotItLocked = !data?.progress.find((p) => p.content === 'SPOT_IT')?.completed;

  if (data?.product.slug === ProductVariant.AVIVA_INPATIENT) {
    return null;
  }

  return (
    <View style={flatCard} testID="Home_quickActions">
      <Heading
        text={$t({ id: 'Home_quickActionsHeading', defaultMessage: 'Add to diaries' })}
        testID="Home_quickActionsHeading"
        style={{ marginBottom: 15 }}
        level={3}
      />
      <View row={fontScale <= 1} childFlex={1}>
        <View>
          <Button
            variant="text"
            text={$t({
              id: 'Home_quickActionActivityEntryButton',
              defaultMessage: 'Add activity',
            })}
            icon={isActivityPlanningLocked ? 'lock' : 'plus'}
            disabled={isActivityPlanningLocked}
            color={isActivityPlanningLocked ? theme.color.gray100 : undefined}
            onPress={() => {
              navigate('ActivityDiary', {});
              navigate('NewActivityEvent');
            }}
            style={{
              justifyContent: 'flex-start',
            }}
            testID={
              isActivityPlanningLocked
                ? 'Home_quickActionActivityEntryLocked'
                : 'Home_quickActionActivityEntry'
            }
          />
        </View>
        <View>
          <Button
            variant="text"
            text={$t({
              id: 'Home_quickActionMorningSleepEntryButton',
              defaultMessage: 'Morning',
            })}
            onPress={() => {
              navigate('SleepDiary', {});
              navigate('EditSleepDiaryEntry', { step: 'morning', date: today });
            }}
            icon={isSleepDiaryLocked ? 'lock' : 'sun'}
            disabled={isSleepDiaryLocked}
            color={isSleepDiaryLocked ? theme.color.gray100 : undefined}
            style={{}}
            testID={
              isSleepDiaryLocked
                ? 'Home_quickActionMorningSleepEntryLocked'
                : 'Home_quickActionMorningSleepEntry'
            }
          />
        </View>
      </View>
      <View row={fontScale <= 1} childFlex={1}>
        <View>
          <Button
            variant="text"
            text={$t({
              id: 'Home_quickActionThoughtEntryButton',
              defaultMessage: 'Add thought',
            })}
            icon={isSpotItLocked ? 'lock' : 'plus'}
            disabled={isSpotItLocked}
            color={isSpotItLocked ? theme.color.gray100 : undefined}
            onPress={() => {
              navigate('ThoughtDiary', {});
              navigate('NewThoughtDiaryEntry', { step: 'spot', cardStack: 'true' });
            }}
            style={{
              justifyContent: 'flex-start',
              flexBasis: '40%',
            }}
            testID={
              isSpotItLocked ? 'Home_quickActionThoughtEntryLocked' : 'Home_quickActionThoughtEntry'
            }
          />
        </View>
        <View>
          <Button
            variant="text"
            text={$t({
              id: 'Home_quickActionNightSleepEntryButton',
              defaultMessage: 'Night',
            })}
            icon={isSleepDiaryLocked ? 'lock' : 'moon'}
            disabled={isSleepDiaryLocked}
            color={isSleepDiaryLocked ? theme.color.gray100 : undefined}
            onPress={() => {
              navigate('SleepDiary', {});
              navigate('EditSleepDiaryEntry', { step: 'night', date: today });
            }}
            style={{
              justifyContent: 'flex-start',
              flexBasis: '40%',
            }}
            testID={
              isSleepDiaryLocked
                ? 'Home_quickActionNightSleepEntryLocked'
                : 'Home_quickActionNightSleepEntry'
            }
          />
        </View>
      </View>
    </View>
  );
}
