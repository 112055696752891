import { z } from 'zod';

const rating = z.number().int().min(1).max(5);

const Assessment = z.object({
  feelingShortOfBreath: rating,
  racingHeart: rating,
  feelingDizzy: rating,
  troubleConcentrating: rating,
});

export const StressSensitivityAssessment = z
  .object({
    before: Assessment.optional(),
    after: Assessment.optional(),
  })
  .default({});
export type StressSensitivityAssessment = z.infer<typeof StressSensitivityAssessment>;

export function getStressSensitivityResult(data: z.output<typeof Assessment>) {
  const values = Object.values(data);
  const noCount = values.filter((v) => v === 1).length;
  const lowCount = values.filter((v) => v === 2).length;
  const medHighCount = values.filter((v) => v === 3 || v === 4).length;
  const extremeCount = values.filter((v) => v === 5).length;

  if (extremeCount >= 2) {
    return {
      result: 'extreme' as const,
      title: 'You have a significant problem with stress sensitivity',
      description:
        'You answered with mostly 5\'s ("extreme anxiety"), this would suggest that stress sensitivity is a significant problem area for you, and this program may provide you with better strategies for dealing with stress in the future.',
    };
  } else if (medHighCount >= 2) {
    return {
      result: 'moderate' as const,
      title: 'You have a definite problem with stress sensitivity',
      description:
        'You answered with mostly 3\'s and/or 4\'s ("moderate anxiety" or "high anxiety"), this would suggest that you have a definite problem with stress sensitivity, and this program may provide you with healthier ways to cope with stress.',
    };
  } else if (lowCount >= 2) {
    return {
      result: 'low' as const,
      title: 'You might have a little stress sensitivity',
      description:
        'You answered with mostly 2\'s ("little anxiety"), this would suggest that you might have a little stress sensitivity, and this program may provide some helpful tools for managing your stress.',
    };
  } else if (noCount >= 2) {
    return {
      result: 'none' as const,
      title: "You don't have a problem with stress sensitivity",
      description:
        'You answered with mostly 1\'s ("no anxiety"), this would suggest that you don\'t have a problem with stress sensitivity, but this program may still be beneficial in preventing the development of it.',
    };
  }

  return {
    result: 'mixed' as const,
    title: 'You might have some stress sensitivity',
    description:
      'You answered with a mix of anxiety levels, this would suggest that you might have some stress sensitivity, and this program may provide some helpful tools for managing your stress.',
  };
}
