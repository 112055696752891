"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    TimePeriod: function() {
        return TimePeriod;
    },
    YstOnboardingAvailability: function() {
        return YstOnboardingAvailability;
    },
    YstRecurringAvailability: function() {
        return YstRecurringAvailability;
    }
});
var _zod = require("zod");
var TimePeriod = _zod.z.enum([
    'MORNING',
    'AFTERNOON',
    'EVENING'
]);
var YstOnboardingAvailability = _zod.z.object({
    timezone: _zod.z.string(),
    entries: _zod.z.array(_zod.z.object({
        // html inputs use strings instead of date objects
        date: _zod.z.coerce.date(),
        periods: _zod.z.array(TimePeriod).min(1, {
            message: 'You must select at least one time period'
        })
    }))
}).default(function() {
    return {
        entries: [],
        timezone: ''
    };
});
var YstRecurringAvailability = _zod.z.object({
    timezone: _zod.z.string(),
    entries: _zod.z.array(_zod.z.object({
        weekday: _zod.z.number().int().min(0).max(6),
        periods: _zod.z.array(TimePeriod).min(1, {
            message: 'You must select at least one time period'
        })
    }))
}).default(function() {
    return {
        entries: [],
        timezone: ''
    };
});
