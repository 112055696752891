export async function catchChromeAutoplayError<T>(
  factory: () => T,
  onStorybookRetry?: () => void,
): Promise<T | undefined> {
  try {
    return await factory();
  } catch (e) {
    // Chrome doesn't allow playing audio without user interaction. In storybook interaction
    // tests operate via browser JS, so they don't actually appear to
    // chrome as if a user is interacting.
    if (
      e instanceof Error &&
      // https://developer.chrome.com/blog/autoplay/
      e.message.startsWith(
        `play() failed because the user didn't interact with the document first.`,
      ) &&
      process.env.STORYBOOK_CLIENT
    ) {
      onStorybookRetry?.();
      return;
    }
    throw e;
  }
}
