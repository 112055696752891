import { useNavigation } from '@react-navigation/core';
import { useFocusEffect } from '@react-navigation/native';
import { Fragment, ReactNode, useCallback, useMemo, useState } from 'react';
import { TouchableOpacity } from 'react-native';
import { v4 as uuid } from 'uuid';

import { Button } from '@oui/app-core/src/components/Button';
import { Divider } from '@oui/app-core/src/components/Divider';
import { ErrorPresenter } from '@oui/app-core/src/components/ErrorPresenter';
import { Icon } from '@oui/app-core/src/components/Icon';
import { ListItemTextInput } from '@oui/app-core/src/components/ListItemTextInput';
import { PhoneInput } from '@oui/app-core/src/components/PhoneInput';
import { Text } from '@oui/app-core/src/components/Text';
import { TextInput } from '@oui/app-core/src/components/TextInput';
import { UnorderedList } from '@oui/app-core/src/components/UnorderedList';
import { View } from '@oui/app-core/src/components/View';
import { MyStoryMyPlanCompositionDataHash } from '@oui/app-core/src/hooks/useComposition';
import { useContactsPickerResult } from '@oui/app-core/src/hooks/useContactsPickerResult';
import { useCurrentPatient } from '@oui/app-core/src/hooks/useCurrentUser';
import { useForm } from '@oui/app-core/src/hooks/useForm';
import { usePlacesPickerResult } from '@oui/app-core/src/hooks/usePlacesPickerResult';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { logEvent } from '@oui/app-core/src/lib/log';
import { useTheme } from '@oui/app-core/src/styles';
import {
  MyStoryMyPlanContact,
  transformExpoContactToMyStoryMyPlanContact,
} from '@oui/lib/src/transformExpoContactToResponseContact';
import { ProfessionalHelpContact } from '@oui/lib/src/types/avro';

import { ContactListItem } from '../components/Contact';
import { getCrisisTimelineSections } from '../components/CrisisTimelineList';
import { StackScreenProps } from '../types/navigation';

import '../components/Icon';

import { FormModal } from '@oui/app-core/src/components/FormModal';

export const BLANK_LOCATION = {
  ID: '',
  name: '',
  latitude: 0,
  longitude: 0,
};
export const PROFESSIONAL_SERVICES: ProfessionalHelpContact[] = [
  {
    ID: 'national-suicide-prevention-lifeline',
    firstName: 'National Suicide Prevention Lifeline',
    lastName: '',
    localDeviceID: '',
    imageBase64: 'https://storage.googleapis.com/asset.oui.dev/static/NSPL-logo.jpg',
    phone: '988',
  },
  {
    ID: 'veterans-lifeline',
    firstName: 'Veterans Crisis Line',
    lastName: '',
    localDeviceID: '',
    imageBase64: 'https://storage.googleapis.com/asset.oui.dev/static/veterans-logo.jpg',
    phone: '988',
  },
  {
    ID: 'trevor-project',
    firstName: 'The Trevor Project',
    lastName: '',
    localDeviceID: '',
    imageBase64: 'https://storage.googleapis.com/asset.oui.dev/static/trevor-logo.svg',
    phone: '1-866-488-7386',
  },
];

function Step(props: {
  testID?: string;
  num: number;
  isEditing?: boolean;
  isFocused?: boolean;
  isHighlighted?: boolean;
  title: string;
  description?: string;
  onEdit: (() => void) | null;
  editChildren: ReactNode;
  viewChildren: ReactNode;
}) {
  const { theme } = useTheme();
  const isButton = !!(props.isFocused && props.onEdit && !props.isEditing);
  const { $t } = useI18n();
  const inner = (
    <View style={{ gap: 20 }}>
      <View
        row
        style={{
          alignItems: 'stretch',
          justifyContent: 'space-between',
          gap: 12,
        }}
      >
        <View
          row
          accessible
          role="heading"
          flex={1}
          style={{
            gap: 8,
            paddingTop: 12,
          }}
        >
          {props.num > 0 ? (
            <Text
              text={$t(
                { id: 'PatientMyPlan_step', defaultMessage: 'Step {number}' },
                { number: props.num },
              )}
            />
          ) : null}
          <Text text={props.title} weight="semibold" size={17} style={{ flex: 1 }} />
        </View>
        {!props.isEditing && props.onEdit ? (
          <TouchableOpacity
            onPress={props.onEdit}
            aria-label={`Edit ${props.title}`}
            role="button"
            testID="PatientMyPlan_editStepButton"
            style={{
              alignSelf: 'stretch',
              alignItems: 'center',
              justifyContent: 'center',
              paddingLeft: 12,
            }}
            hitSlop={{ top: 20, left: 30, right: 30, bottom: 20 }}
          >
            <Icon name={props.isFocused ? 'arrow-right' : 'edit'} color={theme.color.primary100} />
          </TouchableOpacity>
        ) : (
          <View
            style={{ opacity: 0, paddingLeft: 12 }}
            accessible={false}
            accessibilityElementsHidden
          >
            <Icon name="edit" aria-label={undefined} />
          </View>
        )}
      </View>
      {props.isEditing && props.description ? (
        <Text text={props.description} color={theme.color.gray300} size={15} />
      ) : null}
      {props.isEditing ? props.editChildren : props.viewChildren}
    </View>
  );

  return isButton ? (
    <TouchableOpacity
      onPress={props.onEdit ?? undefined}
      role={props.onEdit ? 'button' : undefined}
      aria-selected={props.isFocused}
      disabled={!props.onEdit}
      testID={props.testID}
      style={
        props.num > 0 && props.isFocused && !props.isEditing
          ? {
              borderColor: theme.color.primary100,
              borderWidth: 2,
              marginHorizontal: -20,
              marginVertical: -10,
              paddingHorizontal: 20,
              paddingVertical: 10,
            }
          : null
      }
    >
      {inner}
    </TouchableOpacity>
  ) : (
    <View
      testID={props.isHighlighted ? `${props.testID}__highlighted` : props.testID}
      style={
        props.isHighlighted
          ? {
              borderColor: theme.color.accentTwo100,
              backgroundColor: theme.color.accent300,
              borderWidth: 2,
              marginHorizontal: -20,
              marginVertical: -10,
              paddingHorizontal: 20,
              paddingVertical: 10,
            }
          : null
      }
    >
      {inner}
    </View>
  );
}

export function EnvironmentSafety({
  data,
  editingSection,
  focusedSection,
  highlightedSections,
  isEditing,
  onEdit,
  onStartEditingSection,
}: {
  data: MyStoryMyPlanCompositionDataHash;
  editingSection?: keyof MyStoryMyPlanCompositionDataHash | null;
  focusedSection?: keyof MyStoryMyPlanCompositionDataHash | null;
  highlightedSections?: Array<keyof MyStoryMyPlanCompositionDataHash> | null;
  isEditing?: boolean;
  onEdit: (data: Partial<MyStoryMyPlanCompositionDataHash>) => void;
  onStartEditingSection?: (section: keyof MyStoryMyPlanCompositionDataHash) => void;
}) {
  const { theme } = useTheme();
  const { $t } = useI18n();
  const { peak } = getCrisisTimelineSections(data.CRISIS_TIMELINE);
  // NOTE this is kinda a hack to detect if `gun` was a selected item in SoloRiskCurveStepBPeak
  // in the future would be nice to store that info explicitly or do a localized match for gun
  const showFirearmSuggetions = peak[0]?.peakMethods?.includes('gun');

  const recentHint =
    focusedSection === 'ENVIRONMENT_SAFETY' && peak[0] ? (
      <Text
        text={`Recently, ${peak[0].text}. I will do the following to secure items that I may use for suicide:`}
      />
    ) : null;
  const myPlanSteps = data ? getMyPlanSteps(data) : [];

  return (
    <View style={{ gap: 20 }}>
      <Step
        testID="PatientMyPlan_environmentSafety"
        num={0}
        title={$t({
          id: 'PatientMyPlan_environmentSafety_title',
          defaultMessage: 'Making your environment safe',
        })}
        description="This is a plan that outlines steps to be taken to secure potentially lethal means for suicide."
        isEditing={editingSection === 'ENVIRONMENT_SAFETY'}
        isFocused={focusedSection === 'ENVIRONMENT_SAFETY'}
        isHighlighted={highlightedSections?.includes('ENVIRONMENT_SAFETY')}
        onEdit={
          (
            focusedSection
              ? myPlanSteps.indexOf(focusedSection) >= myPlanSteps.indexOf('ENVIRONMENT_SAFETY')
              : isEditing
          )
            ? () => onStartEditingSection?.('ENVIRONMENT_SAFETY')
            : null
        }
        editChildren={
          <View style={{ gap: 40 }}>
            <View style={{ gap: 12 }}>
              <Text
                text={$t({
                  id: 'PatientMyPlan_environmentSafety_removalStepsHeading',
                  defaultMessage: 'Ways to secure lethal means',
                })}
                weight="semibold"
                role="none"
              />
              {recentHint}
              <ListItemTextInput
                testID="PatientMyPlan_environmentSafety_removalStepsInput"
                aria-label={$t({
                  id: 'PatientMyPlan_environmentSafety_removalStepsLabel',
                  defaultMessage: 'Ways to secure lethal means',
                })}
                autoFocus
                value={data.ENVIRONMENT_SAFETY.removalSteps ?? []}
                onChangeValue={(newRemovalSteps) =>
                  onEdit({
                    ENVIRONMENT_SAFETY: {
                      ...data.ENVIRONMENT_SAFETY,
                      removalSteps: newRemovalSteps,
                    },
                  })
                }
                bulletColor={theme.color.primary100}
                suggestions={{
                  accordionText: 'Select from suggested ways',
                  items: {
                    ...(showFirearmSuggetions ? SUGGESTED_GUN_REMOVAL_STEPS : null),
                    ...SUGGESTED_REMOVAL_STEPS,
                  },
                }}
              />
            </View>
            <View style={{ gap: 12 }}>
              <Text
                text={$t({
                  id: 'PatientMyPlan_environmentSafety_supportContactsHeading',
                  defaultMessage: 'Who will help me secure these',
                })}
                weight="semibold"
              />
              <PatientMyPlanEditContacts
                section="ENVIRONMENT_SAFETY"
                contacts={data.ENVIRONMENT_SAFETY.supportContacts}
                onEdit={({ contacts }) => {
                  onEdit({
                    ENVIRONMENT_SAFETY: { ...data.ENVIRONMENT_SAFETY, supportContacts: contacts },
                  });
                }}
              />
            </View>
          </View>
        }
        viewChildren={
          data.ENVIRONMENT_SAFETY.removalSteps.length ? (
            <View style={{ gap: 40 }}>
              <View style={{ gap: 12 }}>
                <Text
                  text={$t({
                    id: 'PatientMyPlan_environmentSafety_removalStepsHeading',
                    defaultMessage: 'Ways to secure lethal means',
                  })}
                  weight="semibold"
                />
                {recentHint}
                <UnorderedList
                  items={data.ENVIRONMENT_SAFETY.removalSteps.map(({ text }) => text) ?? []}
                  color={theme.color.accentTwo100}
                  weight="normal"
                />
              </View>
              <View style={{ gap: 12 }}>
                <Text
                  text={$t({
                    id: 'PatientMyPlan_environmentSafety_supportContactsHeading',
                    defaultMessage: 'Who will help me secure these',
                  })}
                  weight="semibold"
                />
                {data.ENVIRONMENT_SAFETY.supportContacts.map((contact, i, arr) => (
                  <Fragment key={contact.ID}>
                    <ContactListItem
                      imageSize={40}
                      imageBorderRadius
                      nameWeight="normal"
                      name={{ first: contact.firstName, last: contact.lastName }}
                      image={
                        contact.imageAssetKey
                          ? { context: 'ENVIRONMENT_SAFETY', key: contact.imageAssetKey }
                          : contact.imageBase64
                      }
                    />
                    {i < arr.length - 1 ? <Divider /> : null}
                  </Fragment>
                ))}
              </View>
            </View>
          ) : null
        }
      />
    </View>
  );
}

function PatientMyPlanEditPlaces({
  value,
  onChangeValue,
}: {
  value: MyStoryMyPlanCompositionDataHash['SOCIAL_DISTRACTIONS']['places'];
  onChangeValue: (
    newValue: MyStoryMyPlanCompositionDataHash['SOCIAL_DISTRACTIONS']['places'],
  ) => unknown;
}) {
  const { theme } = useTheme();
  const { $t } = useI18n();
  const [fullname, setFullname] = useState('');
  const { navigate } = useNavigation<StackScreenProps<'EditMyPlan'>['navigation']>();
  const [showManualLocationModal, setShowManualLocationModal] = useState(false);

  function addManualPlace(place: string) {
    onChangeValue([
      ...value,
      {
        name: place,
        ID: uuid(),
        latitude: 0,
        longitude: 0,
      },
    ]);
  }

  function removePlace(ID: string) {
    onChangeValue(value.filter((v) => v.ID !== ID));
  }

  const returnRoute = usePlacesPickerResult(({ places, permissionDenied }) => {
    if (!permissionDenied) {
      onChangeValue([...value, ...places]);
    }
  });

  return (
    <View flex={1}>
      <View>
        {value.length ? (
          value.map((place, i, arr) => (
            <Fragment key={place.ID}>
              <View row>
                <View flex={1}>
                  <ContactListItem
                    imageSize={40}
                    nameWeight="normal"
                    name={{ first: place.name, last: '' }}
                  />
                </View>
                <Icon
                  aria-label={$t({
                    id: 'PatientMyPlanEditPlaces_removeButton',
                    defaultMessage: 'Remove place',
                  })}
                  size={14}
                  name="close"
                  onPress={() => removePlace(place.ID)}
                  color={theme.color.gray400}
                />
              </View>
              {i < arr.length - 1 ? <Divider /> : null}
            </Fragment>
          ))
        ) : (
          <View
            row
            style={{ gap: 12 }}
            accessible
            aria-label={$t({
              id: 'PatientMyPlanEditPlaces_placeholderAccessibilityLabel',
              defaultMessage: 'Location placeholder',
            })}
          >
            <View
              row
              style={{
                borderRadius: 10,
                backgroundColor: theme.color.gray700,
                width: 35,
                height: 35,
              }}
            />
            <Text
              text={$t({
                id: 'PatientMyPlanEditPlaces_placeholder',
                defaultMessage: 'Add a location',
              })}
              color={theme.color.gray500}
            />
          </View>
        )}
      </View>
      <View style={{ marginTop: 40, gap: 12 }}>
        <Button
          text={$t({ id: 'PatientMyPlanEditPlaces_addButton', defaultMessage: 'Add from map' })}
          testID="PatientMyPlanEditPlaces_addButton"
          alignSelf="center"
          icon="location"
          onPress={() => {
            navigate('PlacesPicker', { returnRoute });
          }}
        />

        <Button
          text={$t({
            id: 'PatientMyPlanEditPlaces_addManuallyButton',
            defaultMessage: 'Or add manually',
          })}
          testID="PatientMyPlanEditPlaces_addManuallyButton"
          alignSelf="center"
          variant="text"
          size="small"
          color={theme.color.gray400}
          onPress={() => {
            setShowManualLocationModal(true);
          }}
        />

        <FormModal
          visible={!!showManualLocationModal}
          onCancel={() => {
            setFullname('');
            setShowManualLocationModal(false);
          }}
          onConfirm={async () => {
            addManualPlace(fullname);
            setFullname('');
            setShowManualLocationModal(false);
          }}
          disableButton={!fullname.length}
          confirmText={$t({
            id: 'PatientMyPlanEditPlaces_saveManuallyButton',
            defaultMessage: 'Add place',
          })}
          confirmTestID="PatientMyPlanEditPlaces_saveManuallyButton"
          title={$t({
            id: 'PatientMyPlanEditPlaces_saveManuallyTitle',
            defaultMessage: 'Place',
          })}
        >
          <View
            style={{
              backgroundColor: theme.color.gray800,
              marginHorizontal: -20,
              padding: 20,
            }}
          >
            <TextInput
              value={fullname}
              onChangeValue={setFullname}
              placeholder={$t({
                id: 'PatientMyPlanEditPlaces_locationInputPlaceholder',
                defaultMessage: 'Location name',
              })}
              testID="PatientMyPlanEditPlaces_locationInput"
            />
          </View>
        </FormModal>
      </View>
    </View>
  );
}

function PatientMyPlanEditContacts({
  section,
  contacts,
  onEdit,
}: {
  section: keyof MyStoryMyPlanCompositionDataHash;
  contacts: ReadonlyArray<MyStoryMyPlanContact>;
  onEdit: (data: { contacts: MyStoryMyPlanContact[] }) => void;
}) {
  const [showManualContactModal, setShowManualContactModal] = useState(false);

  const { theme } = useTheme();
  const { $t } = useI18n();
  const { navigate } = useNavigation<StackScreenProps<'EditMyPlan'>['navigation']>();
  const { validate, clear, bind, data, humanErrors } = useForm<MyStoryMyPlanContact>({
    ID: '',
    firstName: '',
    lastName: '',
    phone: '',
    // These fields are part of MyStoryMyPlanContact but aren't actually used in this form
    localDeviceID: '',
    imageBase64: '',
  });

  function addNewContacts(newContacts: MyStoryMyPlanContact[]) {
    onEdit({ contacts: [...contacts, ...newContacts] });
  }

  const returnRoute = useContactsPickerResult(({ contacts: expoContacts, permissionDenied }) => {
    if (!permissionDenied) {
      const newContacts = expoContacts.map((expoContact) => {
        const transformedContact = transformExpoContactToMyStoryMyPlanContact(expoContact);
        if (expoContact.image?.uri) {
          transformedContact.imageAssetKey = expoContact.image.uri;
        }
        return transformedContact;
      });
      addNewContacts(newContacts);
    }
  });

  return (
    <View>
      {contacts.length ? (
        contacts.map((contact, i, arr) => (
          <Fragment key={i}>
            <View row style={{ justifyContent: 'space-between' }}>
              <View flex={1}>
                <ContactListItem
                  imageSize={40}
                  imageBorderRadius
                  nameWeight="normal"
                  name={{ first: contact.firstName, last: contact.lastName }}
                  image={
                    contact.imageAssetKey
                      ? { context: section, key: contact.imageAssetKey }
                      : contact.imageBase64
                  }
                />
              </View>
              <Icon
                aria-label={$t({
                  id: 'PatientMyPlanEditContacts_removeButton',
                  defaultMessage: 'Remove contact',
                })}
                name="close"
                color={theme.color.gray400}
                size={10}
                onPress={() => {
                  onEdit({ contacts: contacts?.filter((_, j) => i !== j) });
                }}
              />
            </View>
            {i < arr.length - 1 ? <Divider /> : null}
          </Fragment>
        ))
      ) : (
        <View
          row
          style={{ gap: 12 }}
          aria-label={$t({
            id: 'PatientMyPlanEditContacts_placeholderAccessibilityLabel',
            defaultMessage: 'Contact placeholder',
          })}
          accessible
        >
          <View
            row
            style={{
              borderRadius: 30,
              backgroundColor: theme.color.gray700,
              width: 35,
              height: 35,
            }}
          />
          <Text
            text={$t({
              id: 'PatientMyPlanEditContacts_placeholder',
              defaultMessage: 'Add a contact',
            })}
            color={theme.color.gray500}
          />
        </View>
      )}
      <View style={{ marginTop: 40, gap: 12 }}>
        <Button
          text={$t({
            id: 'PatientMyPlanEditContacts_addButton',
            defaultMessage: 'Add from contacts',
          })}
          testID="PatientMyPlanEditContacts_addButton"
          alignSelf="center"
          icon="plus"
          onPress={() => {
            navigate('ContactsPicker', { returnRoute });
          }}
        />

        <Button
          text={$t({
            id: 'PatientMyPlanEditContacts_addManuallyButton',
            defaultMessage: 'Or add manually',
          })}
          testID="PatientMyPlanEditContacts_addManuallyButton"
          alignSelf="center"
          variant="text"
          size="small"
          color={theme.color.gray400}
          onPress={() => {
            setShowManualContactModal(true);
          }}
        />

        <FormModal
          visible={!!showManualContactModal}
          onCancel={() => {
            clear();
            setShowManualContactModal(false);
          }}
          onConfirm={async () => {
            if (validate()) {
              addNewContacts([{ ...data, ID: uuid() }]);
              clear();
              setShowManualContactModal(false);
            }
          }}
          confirmText={$t({
            id: 'PatientMyPlanEditContacts_saveManuallyButton',
            defaultMessage: 'Add contact',
          })}
          confirmTestID="PatientMyPlanEditContacts_saveManuallyButton"
          title={$t({
            id: 'PatientMyPlanEditContacts_saveManuallyTitle',
            defaultMessage: 'Contact details',
          })}
        >
          <View
            style={{
              gap: 20,
              backgroundColor: theme.color.gray800,
              marginHorizontal: -20,
              padding: 20,
            }}
          >
            {Object.keys(humanErrors).length ? <ErrorPresenter formErrors={humanErrors} /> : null}
            <TextInput
              placeholder={$t({
                id: 'PatientMyPlanEditContacts_givenNamePlaceholder',
                defaultMessage: 'Sally',
              })}
              {...bind(['firstName'], {
                validator: { type: 'present' },
                label: $t({
                  id: 'PatientMyPlanEditContacts_givenNameLabel',
                  defaultMessage: 'First name*',
                }),
              })}
            />
            <TextInput
              placeholder={$t({
                id: 'PatientMyPlanEditContacts_familyNamePlaceholder',
                defaultMessage: 'Smith',
              })}
              {...bind(['lastName'], {
                validator: { type: 'present' },
                label: $t({
                  id: 'PatientMyPlanEditContacts_familyNameLabel',
                  defaultMessage: 'Last name*',
                }),
              })}
            />
            <PhoneInput
              {...bind(['phone'], {
                validator: { type: 'phone', allowBlank: true },
                label: $t({
                  id: 'PatientMyPlanEditContacts_phoneLabel',
                  defaultMessage: 'Phone number',
                }),
              })}
            />
          </View>
        </FormModal>
      </View>
    </View>
  );
}

export const getMyPlanSteps = (
  data: MyStoryMyPlanCompositionDataHash,
): Array<keyof MyStoryMyPlanCompositionDataHash> => {
  if (data.REASONS_FOR_LIVING) {
    return [
      'WARNING_SIGNS',
      'COPING_STRATEGIES',
      'REASONS_FOR_LIVING',
      'HELP_CONTACTS',
      'PROFESSIONAL_HELP_CONTACTS',
      'ENVIRONMENT_SAFETY',
    ];
  }
  return [
    'WARNING_SIGNS',
    'COPING_STRATEGIES',
    'SOCIAL_DISTRACTIONS',
    'HELP_CONTACTS',
    'PROFESSIONAL_HELP_CONTACTS',
    'ENVIRONMENT_SAFETY',
  ];
};

const SUGGESTED_COPING_STRATEGIES = [
  'Watch a movie or TV show',
  'Listen to music',
  'Sing',
  'Play with a pet',
  'Go for a walk or exercising',
  'Take a warm bath or shower',
  'Read a book',
  'Read spiritual or religious material',
  'Meditate',
  'Do relaxation or breathing exercises',
  'Pray',
  'Do a puzzle',
  'Think about a positive upcoming event',
  'Think about positive memories',
  'Think about loved ones',
  'Think about the future',
  'Focus on the important things in my life',
  'Look at pictures of friends',
  'Read letters or emails from family members',
  'Eat a favorite food',
  'Cook or bake',
  'Play a sport',
];

const INPATIENT_SUGGESTED_COPING_STRATEGIES = [
  'Do relaxation or mindfulness',
  'Watch a movie or TV show',
  'Listen to music',
  'Sing',
  'Play with a pet',
  'Go for a walk or exercising',
  'Take a warm bath or shower',
  'Read a book',
  'Read spiritual or religious material',
  'Meditate',
  'Pray',
  'Do a puzzle',
  'Think about a positive upcoming event',
  'Think about positive memories',
  'Think about loved ones',
  'Think about the future',
  'Focus on the important things in my life',
  'Look at pictures of friends',
  'Read letters or emails from family members',
  'Eat a favorite food',
  'Cook or bake',
  'Play a sport',
];

const SUGGESTED_REASONS_FOR_LIVING = [
  'Family and friends',
  'Pets',
  'Goals or aspirations',
  'Hopes and dreams of the future',
  'Creativity, art, music',
  'Religion',
  'Help others or volunteering',
];

const SUGGESTED_GUN_REMOVAL_STEPS = [
  'Give key piece of gun to family or friend',
  'Store gun in safe held by family or friend',
  'Store unloaded gun & ammo separately',
  'Use a caplock or trigger lock',
];

const SUGGESTED_REMOVAL_STEPS = [
  'Give to family or friend',
  'Dispose of',
  'Tell someone I live with to hold it for me',
  'Lock away and give key to someone else',
];

export function PatientMyPlan({
  data,
  editingSection,
  focusedSection,
  highlightedSections,
  isEditing,
  onEdit,
  onStartEditingSection,
}: {
  data: MyStoryMyPlanCompositionDataHash;
  editingSection?: keyof MyStoryMyPlanCompositionDataHash | null;
  focusedSection?: keyof MyStoryMyPlanCompositionDataHash | null;
  highlightedSections?: Array<keyof MyStoryMyPlanCompositionDataHash> | null;
  isEditing?: boolean;
  onEdit: (data: Partial<MyStoryMyPlanCompositionDataHash>) => void;
  onStartEditingSection?: (section: keyof MyStoryMyPlanCompositionDataHash) => void;
}) {
  const { theme } = useTheme();
  const { $t } = useI18n();
  const { navigate } = useNavigation<StackScreenProps<'MyPlan'>['navigation']>();
  const warningSignSuggestions = useMemo(() => {
    const { before } = getCrisisTimelineSections(data.CRISIS_TIMELINE);
    return before.map((b) => b.text);
  }, [data.CRISIS_TIMELINE]);

  useFocusEffect(
    useCallback(() => {
      logEvent('view_myplan');
    }, []),
  );

  const hasReasonsForLiving = !!data.REASONS_FOR_LIVING;

  const role = useCurrentPatient();
  const myPlanSteps = data ? getMyPlanSteps(data) : [];

  const warningSigns = (
    <Step
      testID="PatientMyPlan_warningSigns"
      num={1}
      title={$t({ id: 'PatientMyPlan_warningSigns_title', defaultMessage: 'Warning signs' })}
      description={$t({
        id: 'PatientMyPlan_warningSigns_description',
        defaultMessage:
          'Warning signs are thoughts, feelings, or behaviors that you have before a suicidal crisis escalates. These serve as a cue to use the MyPlan.',
      })}
      isEditing={editingSection === 'WARNING_SIGNS'}
      isFocused={focusedSection === 'WARNING_SIGNS'}
      isHighlighted={highlightedSections?.includes('WARNING_SIGNS')}
      onEdit={
        (
          focusedSection
            ? myPlanSteps.indexOf(focusedSection) >= myPlanSteps.indexOf('WARNING_SIGNS')
            : isEditing
        )
          ? () => onStartEditingSection?.('WARNING_SIGNS')
          : null
      }
      editChildren={
        <ListItemTextInput
          aria-label={$t({
            id: 'PatientMyPlan_warningSigns_title',
            defaultMessage: 'Warning signs',
          })}
          testID="PatientMyPlan_warningSignsInput"
          autoFocus
          value={data.WARNING_SIGNS ?? []}
          onChangeValue={(newWarningSigns) => onEdit({ WARNING_SIGNS: newWarningSigns })}
          bulletColor={theme.color.primary100}
          suggestions={
            warningSignSuggestions.length
              ? {
                  accordionText: $t({
                    id: 'PatientMyPlan_warningSigns_selectButton',
                    defaultMessage: 'Select from your risk curve',
                  }),
                  items: warningSignSuggestions,
                }
              : undefined
          }
        />
      }
      viewChildren={
        data.WARNING_SIGNS.length ? (
          <UnorderedList
            testID="PatientMyPlan_warningSignsList"
            items={data.WARNING_SIGNS.map(({ text }) => text) ?? []}
            color="#CB3D3D"
            weight="normal"
          />
        ) : null
      }
    />
  );

  const selfManagementTitle = hasReasonsForLiving
    ? $t({ id: 'PatientMyPlan_selfManagement_titleV2', defaultMessage: 'Things I can do myself' })
    : $t({ id: 'PatientMyPlan_selfManagement_title', defaultMessage: 'Coping strategies' });
  const selfManagement = (
    <Step
      testID="PatientMyPlan_selfManagement"
      num={2}
      title={selfManagementTitle}
      description={$t({
        id: 'PatientMyPlan_selfManagement_description',
        defaultMessage:
          'These are activities you can do and places you can go to alone that are helpful for coping during times of stress.',
      })}
      isEditing={editingSection === 'COPING_STRATEGIES'}
      isFocused={focusedSection === 'COPING_STRATEGIES'}
      isHighlighted={highlightedSections?.includes('COPING_STRATEGIES')}
      onEdit={
        (
          focusedSection
            ? myPlanSteps.indexOf(focusedSection) >= myPlanSteps.indexOf('COPING_STRATEGIES')
            : isEditing
        )
          ? () => onStartEditingSection?.('COPING_STRATEGIES')
          : null
      }
      editChildren={
        <ListItemTextInput
          aria-label={selfManagementTitle}
          testID="PatientMyPlan_selfManagementInput"
          autoFocus
          value={data.COPING_STRATEGIES ?? []}
          onChangeValue={(newCoping) => onEdit({ COPING_STRATEGIES: newCoping })}
          bulletColor={theme.color.accentTwo100}
          suggestions={{
            accordionText: $t({
              id: 'PatientMyPlan_selfManagement_selectButton',
              defaultMessage: 'Select from suggested strategies',
            }),
            items:
              role?.product.slug === 'AVIVA_INPATIENT'
                ? INPATIENT_SUGGESTED_COPING_STRATEGIES
                : SUGGESTED_COPING_STRATEGIES,
            customLabels: {
              'Do relaxation or mindfulness': (
                <Button
                  text={'Do relaxation or mindfulness'}
                  variant="text"
                  alignSelf="center"
                  size="small"
                  onPress={() => {
                    // @ts-expect-error TODO
                    navigate('Relaxation', {});
                  }}
                />
              ),
            },
          }}
        />
      }
      viewChildren={
        data.COPING_STRATEGIES.length ? (
          <UnorderedList
            testID="PatientMyPlan_selfManagementList"
            items={data.COPING_STRATEGIES.map(({ text }) => text) ?? []}
            customLabels={{
              'Do relaxation or mindfulness': (
                <View
                  row
                  style={{
                    gap: 6,
                    marginTop: -4,
                    marginLeft: -2,
                  }}
                >
                  <Button
                    text={'Do relaxation or mindfulness'}
                    variant="text"
                    size="small"
                    onPress={() => {
                      // @ts-expect-error TODO
                      navigate('Relaxation', {});
                    }}
                  />
                  <Icon
                    name="arrow-right"
                    color={theme.color.primary100}
                    size={16}
                    style={{
                      marginTop: 2,
                    }}
                  />
                </View>
              ),
            }}
            color={theme.color.accentTwo100}
            weight="normal"
          />
        ) : null
      }
    />
  );

  const socialDistractions = (
    <Step
      testID="PatientMyPlan_socialDistractions"
      num={3}
      title={$t({
        id: 'PatientMyPlan_socialDistractions_title',
        defaultMessage: 'Social distractions',
      })}
      description={$t({
        id: 'PatientMyPlan_socialDistractions_description',
        defaultMessage:
          'Talking to others or going to places with other people helps you not think about your problems which allows time for the suicidal thoughts to subside. People in this step are not to be used to share or discuss a crisis.',
      })}
      isEditing={editingSection === 'SOCIAL_DISTRACTIONS'}
      isFocused={focusedSection === 'SOCIAL_DISTRACTIONS'}
      isHighlighted={highlightedSections?.includes('SOCIAL_DISTRACTIONS')}
      onEdit={
        (
          focusedSection
            ? myPlanSteps.indexOf(focusedSection) >= myPlanSteps.indexOf('SOCIAL_DISTRACTIONS')
            : isEditing
        )
          ? () => onStartEditingSection?.('SOCIAL_DISTRACTIONS')
          : null
      }
      editChildren={
        <View style={{ gap: 12 }}>
          <Text
            text={$t({
              id: 'PatientMyPlan_socialDistractions_peopleHeader',
              defaultMessage: 'People that provide distraction',
            })}
            weight="semibold"
            size={17}
            role="heading"
            color={theme.color.gray200}
            style={{ marginTop: 20 }}
          />
          <PatientMyPlanEditContacts
            section="SOCIAL_DISTRACTIONS"
            contacts={data.SOCIAL_DISTRACTIONS.contacts}
            onEdit={({ contacts: newContacts }) =>
              onEdit({
                SOCIAL_DISTRACTIONS: { ...data.SOCIAL_DISTRACTIONS, contacts: newContacts },
              })
            }
          />
          <Text
            text={$t({
              id: 'PatientMyPlan_socialDistractions_placesHeader',
              defaultMessage: 'Places that provide distraction',
            })}
            weight="semibold"
            size={17}
            role="heading"
            color={theme.color.gray200}
            style={{ marginTop: 30 }}
          />
          <PatientMyPlanEditPlaces
            value={data.SOCIAL_DISTRACTIONS.places}
            onChangeValue={(newPlaces) =>
              onEdit({
                SOCIAL_DISTRACTIONS: { ...data.SOCIAL_DISTRACTIONS, places: newPlaces },
              })
            }
          />
        </View>
      }
      viewChildren={
        data.SOCIAL_DISTRACTIONS.contacts.length || data.SOCIAL_DISTRACTIONS.places.length ? (
          <View>
            {data.SOCIAL_DISTRACTIONS.contacts.map((c, i, arr) => (
              <Fragment key={i}>
                <ContactListItem
                  imageSize={40}
                  imageBorderRadius
                  name={{ first: c.firstName, last: c.lastName }}
                  nameWeight="normal"
                  image={
                    c.imageAssetKey
                      ? { context: 'SOCIAL_DISTRACTIONS', key: c.imageAssetKey }
                      : c.imageBase64
                  }
                  phone={isEditing ? undefined : c.phone}
                />
                {i < arr.length - 1 || data.SOCIAL_DISTRACTIONS.places.length ? <Divider /> : null}
              </Fragment>
            ))}
            {data.SOCIAL_DISTRACTIONS.places.map((place, i, arr) => (
              <Fragment key={place.ID}>
                <ContactListItem
                  testID={`PatientMyPlan_socialDistractions_place_${i}`}
                  imageSize={40}
                  key={place.ID}
                  name={{ first: place.name, last: '' }}
                  nameWeight="normal"
                />
                {i < arr.length - 1 ? <Divider /> : null}
              </Fragment>
            ))}
          </View>
        ) : null
      }
    />
  );

  const reasonsForLiving = data.REASONS_FOR_LIVING ? (
    <Step
      testID="PatientMyPlan_reasonsForLiving"
      num={3}
      title={$t({
        id: 'PatientMyPlan_reasonsForLiving_title',
        defaultMessage: 'Reasons for living',
      })}
      description={$t({
        id: 'PatientMyPlan_reasonsForLiving_description',
        defaultMessage:
          'With all that has been going on in your life, think about what helps to keep you alive and going on a day-to-day basis. These are your reasons for living.',
      })}
      isEditing={editingSection === 'REASONS_FOR_LIVING'}
      isFocused={focusedSection === 'REASONS_FOR_LIVING'}
      isHighlighted={highlightedSections?.includes('REASONS_FOR_LIVING')}
      onEdit={
        (
          focusedSection
            ? myPlanSteps.indexOf(focusedSection) >= myPlanSteps.indexOf('REASONS_FOR_LIVING')
            : isEditing
        )
          ? () => onStartEditingSection?.('REASONS_FOR_LIVING')
          : null
      }
      editChildren={
        <ListItemTextInput
          aria-label="Reasons for living"
          testID="PatientMyPlan_reasonsForLivingInput"
          autoFocus
          value={data.REASONS_FOR_LIVING ?? []}
          onChangeValue={(newReasons) => onEdit({ REASONS_FOR_LIVING: newReasons })}
          bulletColor={theme.color.accentThree100}
          suggestions={{
            accordionText: $t({
              id: 'PatientMyPlan_reasonsForLiving_selectButton',
              defaultMessage: 'List of common reasons for living',
            }),
            items: SUGGESTED_REASONS_FOR_LIVING,
          }}
        />
      }
      viewChildren={
        data.REASONS_FOR_LIVING.length ? (
          <UnorderedList
            testID="PatientMyPlan_reasonsForLivingList"
            items={data.REASONS_FOR_LIVING.map(({ text }) => text) ?? []}
            color={theme.color.accentThree100}
            weight="normal"
          />
        ) : null
      }
    />
  ) : null;

  const helpContactsTitle = hasReasonsForLiving
    ? $t({ id: 'PatientMyPlan_helpContacts_titleV2', defaultMessage: 'People I can talk to' })
    : $t({ id: 'PatientMyPlan_helpContacts_title', defaultMessage: 'People I can ask for help' });
  const helpContacts = (
    <Step
      testID="PatientMyPlan_helpContacts"
      num={4}
      title={helpContactsTitle}
      description={$t({
        id: 'PatientMyPlan_helpContacts_description',
        defaultMessage:
          'Connecting with others helps you to not think about your problems which allows time for the suicidal thoughts to subside. The people in this step can either help distract you or can help you in a crisis.',
      })}
      isEditing={editingSection === 'HELP_CONTACTS'}
      isFocused={focusedSection === 'HELP_CONTACTS'}
      isHighlighted={highlightedSections?.includes('HELP_CONTACTS')}
      onEdit={
        (
          focusedSection
            ? myPlanSteps.indexOf(focusedSection) >= myPlanSteps.indexOf('HELP_CONTACTS')
            : isEditing
        )
          ? () => onStartEditingSection?.('HELP_CONTACTS')
          : null
      }
      editChildren={
        <PatientMyPlanEditContacts
          section="HELP_CONTACTS"
          contacts={data.HELP_CONTACTS}
          onEdit={({ contacts: newContacts }) =>
            onEdit({
              HELP_CONTACTS: newContacts,
            })
          }
        />
      }
      viewChildren={
        data.HELP_CONTACTS.length ? (
          <View>
            {data.HELP_CONTACTS.map((contact, i, arr) => (
              <Fragment key={contact.ID}>
                <ContactListItem
                  imageSize={40}
                  imageBorderRadius
                  nameWeight="normal"
                  name={{ first: contact.firstName, last: contact.lastName }}
                  image={
                    contact.imageAssetKey
                      ? { context: 'HELP_CONTACTS', key: contact.imageAssetKey }
                      : contact.imageBase64
                  }
                />
                {i === arr.length - 1 ? null : <Divider />}
              </Fragment>
            ))}
          </View>
        ) : null
      }
    />
  );

  const professionalHelpContacts = (
    <Step
      testID="PatientMyPlan_professionalHelpContacts"
      num={5}
      title={$t({
        id: 'PatientMyPlan_professionalHelpContacts_title',
        defaultMessage: 'Professional help',
      })}
      description={$t({
        id: 'PatientMyPlan_professionalHelpContacts_description',
        defaultMessage:
          'These are professionals who can help you in a crisis. We’ve added the 988 Suicide & Crisis Lifeline to help you with this step.',
      })}
      isEditing={editingSection === 'PROFESSIONAL_HELP_CONTACTS'}
      isFocused={focusedSection === 'PROFESSIONAL_HELP_CONTACTS'}
      isHighlighted={highlightedSections?.includes('PROFESSIONAL_HELP_CONTACTS')}
      onEdit={
        (
          focusedSection
            ? myPlanSteps.indexOf(focusedSection) >=
              myPlanSteps.indexOf('PROFESSIONAL_HELP_CONTACTS')
            : isEditing
        )
          ? () => onStartEditingSection?.('PROFESSIONAL_HELP_CONTACTS')
          : null
      }
      editChildren={
        <PatientMyPlanEditContacts
          section="PROFESSIONAL_HELP_CONTACTS"
          contacts={data.PROFESSIONAL_HELP_CONTACTS.map(({ contact }) => contact)}
          onEdit={({ contacts: newContacts }) =>
            onEdit({
              PROFESSIONAL_HELP_CONTACTS: newContacts.map((c) => ({
                contact: c,
                location: BLANK_LOCATION,
              })),
            })
          }
        />
      }
      viewChildren={
        data.PROFESSIONAL_HELP_CONTACTS.length ? (
          <View>
            {data.PROFESSIONAL_HELP_CONTACTS.map(({ contact }, i, arr) => (
              <Fragment key={contact.ID}>
                <ContactListItem
                  imageSize={40}
                  imageBorderRadius
                  nameWeight="normal"
                  name={{ first: contact.firstName, last: contact.lastName }}
                  phone={contact.phone}
                  image={
                    contact.imageAssetKey
                      ? { context: 'PROFESSIONAL_HELP_CONTACTS', key: contact.imageAssetKey }
                      : contact.imageBase64
                  }
                />
                {i === arr.length - 1 ? null : <Divider />}
              </Fragment>
            ))}
          </View>
        ) : null
      }
    />
  );

  switch (editingSection) {
    case 'WARNING_SIGNS': {
      return warningSigns;
    }
    case 'COPING_STRATEGIES': {
      return selfManagement;
    }
    case 'REASONS_FOR_LIVING': {
      return reasonsForLiving;
    }
    case 'SOCIAL_DISTRACTIONS': {
      return socialDistractions;
    }
    case 'HELP_CONTACTS': {
      return helpContacts;
    }
    case 'PROFESSIONAL_HELP_CONTACTS': {
      return professionalHelpContacts;
    }
    default: {
      return (
        <View style={{ gap: focusedSection ? 30 : 50 }}>
          {warningSigns}
          {selfManagement}
          {hasReasonsForLiving ? reasonsForLiving : socialDistractions}
          {helpContacts}
          {professionalHelpContacts}
        </View>
      );
    }
  }
}
