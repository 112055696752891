import { QueryResult, useQuery } from '@apollo/client';
import { createContext, ReactNode, useMemo } from 'react';

import { ResultOf, VariablesOf } from '@oui/lib/src/graphql/tada';

import { CurrentUserQuery } from '../hooks/useCurrentUser.query';
import { useIsLoggedIn } from '../hooks/useIsLoggedIn';
import { setUserProperties } from '../lib/log';

export const CurrentUserContext = createContext<
  QueryResult<ResultOf<typeof CurrentUserQuery>, VariablesOf<typeof CurrentUserQuery>> & {
    isAdmin: boolean;
    isLoggedIn: boolean;
  }
>({} as any); // eslint-disable-line

export function CurrentUserProvider({ children }: { children: ReactNode }) {
  const isLoggedIn = useIsLoggedIn();
  const { data, ...rest } = useQuery(CurrentUserQuery, {
    skip: !isLoggedIn,
  });

  const value = useMemo(() => {
    const user = data?.user;

    if (isLoggedIn && user?.role) {
      setUserProperties({
        // productVariant is a deprecated property that we are sending for legacy purposes.
        // productSlug should be used for creation of new charts / analyses going forward
        productVariant: user.role.product.slug,
        productSlug: user.role.product.slug,
        productStatic: user.role.productStatic,
        roleID: user.role.ID,
      });

      if (user.role.productConfig?.__typename === 'RoleAvivaConfig') {
        setUserProperties({
          productVersion: user.role.productConfig.productVersion,
          onboardingVariant: user.role.productConfig.onboardingVariant,
        });
      }
    }

    return {
      data,
      ...rest,
      loading: rest.loading && !data?.user,
      isAdmin: !!data?.user?.attributes.admin,
      isLoggedIn: isLoggedIn && !!data?.user,
    };
  }, [data, rest, isLoggedIn]);

  return <CurrentUserContext.Provider value={value}>{children}</CurrentUserContext.Provider>;
}
