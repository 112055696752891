import { z } from 'zod';

export const TimePeriod = z.enum(['MORNING', 'AFTERNOON', 'EVENING']);

export const YstOnboardingAvailability = z
  .object({
    timezone: z.string(),
    entries: z.array(
      z.object({
        // html inputs use strings instead of date objects
        date: z.coerce.date(),
        periods: z.array(TimePeriod).min(1, {
          message: 'You must select at least one time period',
        }),
      }),
    ),
  })
  .default(() => ({ entries: [], timezone: '' }));
export type YstOnboardingAvailability = z.infer<typeof YstOnboardingAvailability>;

export const YstRecurringAvailability = z
  .object({
    timezone: z.string(),
    entries: z.array(
      z.object({
        weekday: z.number().int().min(0).max(6),
        periods: z.array(TimePeriod).min(1, {
          message: 'You must select at least one time period',
        }),
      }),
    ),
  })
  .default(() => ({ entries: [], timezone: '' }));
export type YstRecurringAvailability = z.infer<typeof YstRecurringAvailability>;
