import { useLazyQuery, useQuery } from '@apollo/client';
import { useFocusEffect } from '@react-navigation/native';
import * as Sentry from '@sentry/core';
import { useCallback, useContext, useMemo, useRef, useState } from 'react';

import { graphql } from '@oui/lib/src/graphql/tada';
import { ContentType } from '@oui/lib/src/types/graphql.generated';

import { CurrentUserContext } from '../components/CurrentUserContext';
import { Environment, environment } from '../constants';

export * from './useCurrentUser.query';

// We currently dont allow staff users to store/fetch assets so we can't include this in the CurrentUser query
// avatar: asset(context: "user", key: "avatar")
export const SelfPatientQuery = graphql(`
  query SelfPatient {
    user {
      ID
      role {
        ID
        progress {
          content
          completion
          completed
          updatedAt
        }
      }
    }
  }
`);

export const CurrentUserOrganizationsQuery = graphql(`
  query CurrentUserOrganizations($query: String) {
    organizations(query: $query) {
      ID
      name
      productVersion
      productVariants
      caringContactsEnabled
      isTrialOrganization
    }
  }
`);

export function useCurrentUser() {
  return useContext(CurrentUserContext);
}

export function useCurrentPatient() {
  const { data: user } = useCurrentUser();
  if (
    // though the function is called useCurrent"Patient", the term "Patient" used to include all
    // non-staff roles
    user?.user?.role?.roleType === 'PATIENT' ||
    user?.user?.role?.roleType === 'SUPPORTER' ||
    user?.user?.role?.roleType === 'GUARDIAN'
  ) {
    return user.user.role;
  }
  return null;
}

export function useCurrentPatientID() {
  const role = useCurrentPatient();
  return role?.ID ?? null;
}

export function useProgress() {
  return useQuery(SelfPatientQuery);
}

export function useProgressFetcher() {
  const [fetch] = useLazyQuery(SelfPatientQuery, {
    fetchPolicy: 'network-only',
  });
  return fetch;
}

export function useProgressByContent() {
  const { data, ...rest } = useProgress();
  const progress = useMemo(() => {
    const items = data?.user?.role?.progress ?? [];
    return items.reduce<Partial<Record<ContentType, (typeof items)[number]>>>((carry, p) => {
      carry[p.content as ContentType] = p;
      return carry;
    }, {});
  }, [data]);
  return { data: progress, ...rest };
}

export function useProgressComplete(
  contentName: ContentType,
  completeOrNotComplete: boolean = true,
): {
  loaded?: boolean;
  completeResult: boolean;
  maybeCompleteResult?: boolean;
  refetch: () => Promise<unknown>;
} {
  const [stale, setStale] = useState(false);
  const staleRef = useRef(stale);
  staleRef.current = stale;
  const { refetch, data } = useProgress();

  const progressEntry = data?.user?.role?.progress.find((e) => e.content === contentName);
  const completeResult =
    (!completeOrNotComplete && !progressEntry) ||
    progressEntry?.completed === completeOrNotComplete;

  const completeResultRef = useRef(completeResult);
  completeResultRef.current = completeResult;

  useFocusEffect(
    useCallback(() => {
      if (completeResultRef.current !== true) {
        if (staleRef.current) {
          try {
            refetch?.()
              .then(() => {
                setStale(false);
              })
              .catch((e) => {
                setStale(false);
              });
          } catch (e) {
            if (environment !== Environment.DEVELOPMENT) {
              Sentry.captureException(e);
            }
          }
        }
        return () => {
          // if completeResult is already in it's target end state, we dont need to worry about
          // marking as stale to refetch later.
          setStale(!completeResultRef.current);
        };
      }
      return;
    }, [refetch]),
  );

  return stale
    ? { completeResult: false, maybeCompleteResult: false, loaded: false, refetch }
    : {
        completeResult,
        maybeCompleteResult: progressEntry
          ? progressEntry.completed === completeOrNotComplete
          : undefined,
        refetch,
        loaded: !!data?.user?.role?.progress,
      };
}

export function useOrganizations() {
  return useQuery(CurrentUserOrganizationsQuery);
}
