import { useMutation, useQuery } from '@apollo/client';
import { useHeaderHeight } from '@react-navigation/elements';
import { RouteProp, useFocusEffect, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import * as ScreenOrientation from 'expo-screen-orientation';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import {
  createContext,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Image, PixelRatio, Platform, StyleSheet, TouchableOpacity } from 'react-native';
import { SystemBars } from 'react-native-edge-to-edge';

import {
  MultipleChoiceItem,
  SetData,
  SingleChoiceItem,
  VideoItem,
} from '@oui/lib/src/content/quizSet/quizSetData';
import { getQuizSets } from '@oui/lib/src/getQuizSets';
import { graphql } from '@oui/lib/src/graphql/tada';
import { getMuxThumbnail, isMuxUrl } from '@oui/lib/src/muxUrl';
import { decodeHostedQuizSetSlug, isHostedQuizSetSlug } from '@oui/lib/src/quizSetSlug';
import { ProductVariant } from '@oui/lib/src/types';
import { GQLUUID } from '@oui/lib/src/types/scalars';

import { ActivityIndicator } from '../components/ActivityIndicator';
import { Button } from '../components/Button';
import { CardStack, Card as CardStackCard, InternalCardProps } from '../components/CardStack';
import { CheckboxListInput } from '../components/CheckboxListInput';
import { HeaderButtons, HeaderItem } from '../components/HeaderButtons';
import { Icon } from '../components/Icon';
import MediaPlayer from '../components/MediaPlayer';
import { RadioInput } from '../components/RadioInput';
import { ReviewCompleteCard } from '../components/ReviewCompleteCard';
import { RoundedSection } from '../components/RoundedSection';
import { ScrollView } from '../components/ScrollView';
import { Text } from '../components/Text';
import { View } from '../components/View';
import { useArtifactRequest } from '../hooks/useArtifactResult';
import { useCurrentPatientID, useCurrentUser } from '../hooks/useCurrentUser';
import { useWindowDimensions } from '../hooks/useWindowDimensions';
import { CHAT_PREVIEW_BORDER_RADIUS, useTheme } from '../styles';
import { QuizSetParams } from '../types/navigation';

const CompletedQuizSetsQueryName = 'CompletedQuizSets';
const CompletedQuizSetsQuery = graphql(`
  query CompletedQuizSets($input: CompletedQuizSetsForPatientInput!) {
    completedQuizSetsForPatient(input: $input) {
      patientID
      completedQuizSets {
        quizSetID
        slug
      }
    }
  }
`);

const CompleteQuizSetMutation = graphql(`
  mutation CompleteQuizSet($input: CompleteQuizSetForPatientInput!) {
    completeQuizSetForPatient(input: $input) {
      patientID
      completedQuizSet {
        quizSetID
        slug
      }
    }
  }
`);

export const QuizSetCollectionQuery = graphql(`
  query QuizSetCollection($quizSetCollectionID: UUID!) {
    quizSetCollectionByID(quizSetCollectionID: $quizSetCollectionID) {
      quizSetCollectionID
      sets {
        quizSetID
        ID
        name
        description
        previewTime
        previewOptions {
          time
          height
          fitMode
        }
        items: legacyItems {
          __typename
          ... on LegacyQuizSetItemVideo {
            props {
              url
            }
          }
          ... on LegacyQuizSetItemMultipleChoiceQuestion {
            props {
              label
            }
            dir {
              feedback
              prompt
              afterAll
            }
          }
          ... on LegacyQuizSetItemSingleChoiceQuestion {
            props {
              label
            }
            dir {
              feedback
              prompt
              afterAll
            }
          }
        }
      }
    }
  }
`);

const QUIZ_SET_PADDING_VERTICAL = 20;

function getQuizSetPaddingHorizontal({ height, width }: { height: number; width: number }) {
  const consumedHeight =
    // card stack
    QUIZ_SET_PADDING_VERTICAL +
    // card stack vertical position of active card
    20 +
    // card stack page index + arrows 30 (w/marginTop of 20)
    50;

  let horizontalPadding = 20;

  for (let i = 0; i < 60; i = i + 5) {
    horizontalPadding = 20 + i;
    const resultingAspectRatio = (width - horizontalPadding * 2) / (height - consumedHeight);
    if (resultingAspectRatio < 9 / 16) {
      return horizontalPadding;
    }
  }

  return horizontalPadding;
}

function QuizSetItemVideoCard({ item, ...rest }: { item: VideoItem }) {
  const cardProps = rest as unknown as InternalCardProps;
  const isActive = cardProps.index === cardProps.animatedIndex.value;

  return (
    <CardStackCard {...rest} _disableGesture _padding={0}>
      <View
        testID="QuizSetItemVideoCard"
        style={{
          borderRadius: 16,
          overflow: 'hidden',
          width: '100%',
          // depending on aspect ratio of screen, our video may be too large since MediaPlayer's internal
          // height detection mechanism doesn't work unless we set a height here since the card
          // is position absolute. using width + aspectRatio, we force the right height for this view
          // by using getQuizSetPaddingHorizontal to dynamically force/calculate the card's width,
          // we can be assured we'll get a good fit with 9/16
          aspectRatio: 9 / 16,
        }}
      >
        {isActive ? (
          <MediaPlayer
            _enableSkipToEnd={global.e2e}
            // detox has trouble synchronizing on android when video autoplays... so a workaround is to
            // disable autoplay in this case
            autoPlay={!(global.e2e && Platform.OS === 'android')}
            uri={item.props.url}
            onClose={undefined}
            onEnd={() => {
              cardProps.next?.();
            }}
            aspectRatio={9 / 16}
            resizeMode="cover"
          />
        ) : null}
      </View>
    </CardStackCard>
  );
}

function Feedback({
  feedback,
  afterAll,
}: {
  afterAll?: string | null;
  feedback: SingleChoiceItem['dir']['feedback'][string];
}) {
  const { theme } = useTheme();
  const afterText = afterAll ? [feedback.text, afterAll].join('\n\n').trim() : feedback.text;

  return (
    <View
      style={{
        backgroundColor: feedback.correct ? '#f4f8f5' : '#f7f7f9',
        paddingVertical: 20,
        paddingHorizontal: 32,
        marginHorizontal: -20,
        borderBottomWidth: 1,
        borderTopWidth: feedback.correct ? 0 : 1,
        borderColor: '#C5C5D3',
      }}
      spacing={14}
    >
      {feedback.correct ? (
        <View row spacing={14} testID="QuizSetCard_correctFeedback">
          <View
            style={{
              backgroundColor: 'rgba(18, 137, 24, 0.2)',
              borderRadius: 35,
              height: 35,
              width: 35,
              alignItems: 'center',
              justifyContent: 'center',
              alignSelf: 'center',
            }}
          >
            <Icon name="check" color="#035759" aria-label={undefined} size={24} />
          </View>
          <Text text="That's right" size={17} weight="semibold" color="#035759" />
        </View>
      ) : (
        <View row spacing={14} testID="QuizSetCard_incorrectFeedback">
          <View
            style={{
              backgroundColor: 'rgba(206, 106, 107, 0.2)',
              borderRadius: 35,
              height: 35,
              width: 35,
              alignItems: 'center',
              justifyContent: 'center',
              alignSelf: 'center',
            }}
          >
            <Icon name="close" color={theme.color.danger} aria-label={undefined} size={14} />
          </View>
          <Text text="Not quite" size={17} weight="semibold" color={theme.color.danger} />
        </View>
      )}
      {afterText ? <Text text={afterText} size={17} weight="semibold" /> : null}
    </View>
  );
}

function QuizSetItemSingleChoiceCard({ item, ...rest }: { item: SingleChoiceItem }) {
  const cardProps = rest as unknown as InternalCardProps;
  const [value, setValue] = useState('');
  const feedback = item.dir.feedback[value];

  return (
    <CardStackCard {...rest} _disableGesture>
      <RadioInput
        testID="QuizSetItemSingleChoiceCard_input"
        items={Object.entries(item.props.label).map(([key, label]) => {
          return { label, value: key };
        })}
        onChangeValue={setValue}
        value={value}
        aria-label={item.dir.prompt}
        labelSize="large"
        labelSpacing={40}
        label={item.dir.prompt}
      />
      <View spacing={12}>
        {feedback ? <Feedback feedback={feedback} afterAll={item.dir.afterAll} /> : null}
        {value ? (
          <Button
            text="Next"
            onPress={cardProps.next}
            alignSelf="center"
            testID="QuizSetItemSingleChoiceCard_nextButton"
          />
        ) : null}
      </View>
    </CardStackCard>
  );
}

function QuizSetItemMultipleChoiceCard({ item, ...rest }: { item: MultipleChoiceItem }) {
  const cardProps = rest as unknown as InternalCardProps;
  const [value, setValue] = useState<string[]>([]);
  const [submitted, setSubmitted] = useState(false);
  const feedback =
    submitted &&
    (item.dir.feedback[JSON.stringify([...value].sort())] ?? item.dir.feedback.default);

  return (
    <CardStackCard {...rest} _disableGesture>
      <CheckboxListInput
        testID="QuizSetItemMultipleChoiceCard_input"
        label={item.dir.prompt}
        items={item.props.label}
        value={value}
        onChangeValue={(v) => {
          setValue(v);
          if (submitted) setSubmitted(false);
        }}
      />
      <View spacing={12}>
        {feedback ? <Feedback feedback={feedback} afterAll={item.dir.afterAll} /> : null}
        {submitted ? (
          <Button
            text="Next"
            onPress={cardProps.next}
            alignSelf="center"
            testID="QuizSetItemMultipleChoiceCard_nextButton"
          />
        ) : (
          <Button
            text="Choose"
            onPress={() => setSubmitted(true)}
            alignSelf="center"
            testID="QuizSetItemMultipleChoiceCard_chooseButton"
          />
        )}
      </View>
    </CardStackCard>
  );
}

function getQuizSetPreviewUrl(set: SetData, options: Parameters<typeof getMuxThumbnail>[1]) {
  const setVideoUrl = set.items.find((i): i is VideoItem => {
    return i.__typename === 'LegacyQuizSetItemVideo';
  })?.props.url;
  return isMuxUrl(setVideoUrl) ? getMuxThumbnail(setVideoUrl, options) : undefined;
}

// This is exposed via context because in collaborative onboarding (MyStoryMyPlanStack) we render
// a PatientView while logged in as the clinician. In order to show the correct quiz sets, we must
// know which productVariant the clinician's patient has
export const QuizSetProductVariantContext = createContext<ProductVariant>(
  ProductVariant.AVIVA_ADULT,
);

function useLocalQuizSets(slug: string) {
  const defaultProductVariant = useContext(QuizSetProductVariantContext);
  const { data } = useCurrentUser();
  const productSlug = data?.user?.role?.product.slug ?? defaultProductVariant;
  const sets = productSlug ? getQuizSets({ slug: slug, productSlug }) : [];
  return { sets, loading: false };
}

function useHostedQuizSets(variables: { quizSetCollectionID: GQLUUID }) {
  const { data, loading } = useQuery(QuizSetCollectionQuery, {
    variables,
  });
  // temporary coercion until we can remove static SetData (currently incompatible b/c gql
  // data has __typename and quizSetID
  const sets = (data?.quizSetCollectionByID.sets ?? []) as unknown as SetData[];
  return { sets, loading };
}

function useQuizSets(slug: string) {
  if (isHostedQuizSetSlug(slug)) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useHostedQuizSets(decodeHostedQuizSetSlug(slug));
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useLocalQuizSets(slug);
}

export function QuizSetPreview(props: { slug: string; width: number; height: number }) {
  const { theme, Shadow } = useTheme();
  const { sets } = useQuizSets(props.slug);

  if (sets.length === 0) return null;

  // TODO this should move to preview options
  const format = props.slug.includes('VOICEOVER') ? 'png' : 'jpg';
  const images = sets
    .map((s, i) => {
      let width = Math.floor(sets.length > 1 ? props.width / 2 : props.width);
      let height = Math.floor(sets.length > 2 ? props.height / 2 : props.height);

      if (i === 2 && sets.length === 3) {
        width = props.width;
      }

      return {
        url: getQuizSetPreviewUrl(s, {
          format,
          time: s.previewTime ?? 5,
          width,
          height,
          ...omitBy(s.previewOptions, isNil),
          getPixelSizeForLayoutSize: PixelRatio.getPixelSizeForLayoutSize,
        }),
        width,
        height,
      };
    })
    .filter((u) => !!u.url)
    .slice(0, 4);

  return (
    <View
      row
      style={{
        flexWrap: 'wrap',
        width: props.width,
        borderRadius: CHAT_PREVIEW_BORDER_RADIUS,
        overflow: 'hidden',
      }}
    >
      {images.map((image, i) => {
        return (
          <Image
            key={image.url! + i}
            source={{
              uri: image.url,
            }}
            style={{ height: image.height, width: image.width }}
          />
        );
      })}
      <View
        style={[
          { ...StyleSheet.absoluteFillObject, alignItems: 'center', justifyContent: 'center' },
          Shadow.high,
        ]}
      >
        <View
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            width: 50,
            height: 50,
            backgroundColor: 'rgba(255,255,255,0.8)',
            borderRadius: 50 / 2,
          }}
        >
          <Icon color={theme.color.primary100} name="play" size={30} />
        </View>
      </View>
    </View>
  );
}

export function QuizSet(props: { preview?: boolean; slug?: string; onDone?: () => void }) {
  const patientID = useCurrentPatientID();
  const { width, height } = useWindowDimensions();
  const headerHeight = useHeaderHeight();
  const { theme } = useTheme();
  const { goBack, setOptions } =
    useNavigation<StackNavigationProp<{ QuizSet: QuizSetParams }, 'QuizSet'>>();
  const route = useRoute<RouteProp<{ QuizSet: QuizSetParams }, 'QuizSet'>>();
  const params = route.params;
  const slug = props.slug ?? params.slug;
  const { sets, loading } = useQuizSets(slug);
  const defaultTitle = params.slug.includes('LEARN')
    ? 'Learn'
    : params.slug.includes('APPLY')
      ? 'Apply'
      : 'Watch';
  const completedRef = useRef(false);

  let completeQuizSetSlug = slug;
  if (isHostedQuizSetSlug(slug)) {
    const decoded = decodeHostedQuizSetSlug(slug);
    completeQuizSetSlug = decoded.quizSetCollectionID;
  }

  const onDone = () => {
    if (props.onDone) {
      props.onDone();
    } else {
      goBack();
    }
  };

  const [cardStackKey, setCardStackKey] = useState('0');
  const [selectedSetID, setSelectedSetID] = useState<string>(sets.length === 1 ? sets[0].ID : '');
  const { data: completedQuizSets } = useQuery(CompletedQuizSetsQuery, {
    skip: !patientID,
    variables: { input: { patientID: patientID!, slug: completeQuizSetSlug } },
  });
  const completedSetIDs = useMemo(() => {
    return (
      completedQuizSets?.completedQuizSetsForPatient.completedQuizSets.map((qs) => qs.quizSetID) ??
      []
    );
  }, [completedQuizSets]);
  const [completeQuizSet] = useMutation(CompleteQuizSetMutation);
  const complete = completedRef.current || completedSetIDs.length > 0;

  const selectedSet = sets.find((d) => d.ID === selectedSetID);

  useFocusEffect(
    useCallback(() => {
      if (Platform.OS !== 'web') {
        void ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP);
      }
      return () => {
        if (Platform.OS !== 'web') {
          void ScreenOrientation.unlockAsync();
        }
      };
    }, []),
  );

  useLayoutEffect(() => {
    // if loading is required, we may not know sets.length when initializing useState
    if (sets.length === 1) {
      setSelectedSetID(sets[0].ID);
    }
  }, [sets]);

  useArtifactRequest(route.name, complete);

  const title = selectedSet?.name;
  useEffect(() => {
    if (selectedSetID) {
      setOptions({
        headerTitle: title,
        headerTitleAlign: 'center',
        headerLeft:
          sets.length > 1
            ? ({ tintColor }) => (
                <HeaderButtons>
                  <HeaderItem
                    aria-label="Cancel"
                    title=""
                    onPress={() => {
                      setSelectedSetID('');
                    }}
                    iconName="close"
                    color={tintColor}
                    testID="QuizSet_cancelButton"
                  />
                </HeaderButtons>
              )
            : undefined,
      });
    } else {
      setOptions({
        headerLeft: undefined,
        headerTitle: '',
      });
    }
  }, [selectedSetID, setOptions, sets.length, title]);

  if (selectedSet) {
    return (
      <ScrollView
        style={{ flex: 1, backgroundColor: 'black' }}
        contentContainerStyle={{
          paddingHorizontal: getQuizSetPaddingHorizontal({ width, height: height - headerHeight }),
          paddingVertical: QUIZ_SET_PADDING_VERTICAL,
          flexGrow: 1,
        }}
        testID="QuizSet_selectedSet"
      >
        <SystemBars style="light" />
        <CardStack
          hideNextButtonIndexes={true}
          pageColor="white"
          key={selectedSetID + cardStackKey}
          hasCompleteCard
        >
          {selectedSet.items.map((c, i) => {
            switch (c.__typename) {
              case 'LegacyQuizSetItemVideo':
                return <QuizSetItemVideoCard item={c} key={i} />;
              case 'LegacyQuizSetItemSingleChoiceQuestion':
                return <QuizSetItemSingleChoiceCard item={c} key={i} />;
              case 'LegacyQuizSetItemMultipleChoiceQuestion':
                return <QuizSetItemMultipleChoiceCard item={c} key={i} />;
            }
          })}
          <ReviewCompleteCard
            text="Complete"
            onDone={() => {
              if (sets.length > 1) {
                setSelectedSetID('');
              } else {
                onDone();
              }
            }}
            onReset={() => setCardStackKey((k) => k + '1')}
            onActive={() => {
              completedRef.current = true;
              void completeQuizSet({
                variables: {
                  input: {
                    slug: completeQuizSetSlug,
                    quizSetID: selectedSetID,
                    patientID: patientID!,
                  },
                },
                refetchQueries: [CompletedQuizSetsQueryName],
              });
            }}
            resetText="Reset"
          />
        </CardStack>
      </ScrollView>
    );
  }

  return (
    <RoundedSection
      color="black"
      secondaryColor={loading ? 'black' : 'white'}
      title={loading ? ' ' : params.title || defaultTitle}
      preview={props.preview}
      testID="QuizSet"
    >
      {loading ? (
        <ActivityIndicator />
      ) : (
        <>
          <Text
            text="Choose someone"
            size={21}
            weight="bold"
            textAlign="center"
            style={{ marginTop: 20, marginBottom: 40 }}
          />
          <View spacing={60}>
            {sets.map((set, i) => {
              const onPress = () => setSelectedSetID(set.ID);
              const label = `Watch ${set.name}`;
              const muxUrl = getQuizSetPreviewUrl(set, {
                time: set.previewTime ?? 5,
                format: params.slug.includes('VOICEOVER') ? 'png' : 'jpg',
                width: 156,
                height: 125,
                getPixelSizeForLayoutSize: PixelRatio.getPixelSizeForLayoutSize,
              });
              const isComplete = completedSetIDs.includes(set.ID);

              return (
                <Fragment key={set.ID}>
                  <TouchableOpacity
                    role="button"
                    aria-label={label}
                    onPress={onPress}
                    testID={`QuizSet_item_${i}`}
                  >
                    <View row spacing={22}>
                      <View>
                        <View
                          style={{
                            borderRadius: CHAT_PREVIEW_BORDER_RADIUS,
                            borderWidth: 2,
                            borderColor: theme.color.primary100,
                            backgroundColor: '#eee',
                            height: 125 + 4,
                            width: 156 + 4,
                            overflow: 'hidden',
                          }}
                        >
                          {muxUrl ? (
                            <Image
                              source={{
                                uri: muxUrl,
                              }}
                              style={{ height: 125, width: 156 }}
                            />
                          ) : null}
                        </View>
                        <View
                          flex={1}
                          style={[
                            { marginTop: 5, alignItems: 'center', marginBottom: -28 },
                            isComplete ? null : { display: 'none' },
                          ]}
                          aria-hidden={isComplete}
                          testID={`QuizSet_item_${i}_completed`}
                        >
                          <View row>
                            <Icon name="check" color="#035759" size={24} />
                            <Text text="Completed" color="#035759" weight="semibold" />
                          </View>
                        </View>
                      </View>
                      <View flex={1} spacing={15}>
                        <Text
                          text={set.description}
                          style={{
                            flex: 1,
                          }}
                        />
                        <Button onPress={onPress} text={label} variant="text" icon="play" />
                      </View>
                    </View>
                  </TouchableOpacity>
                </Fragment>
              );
            })}
            {complete ? <Button text="Done" onPress={onDone} alignSelf="center" /> : null}
          </View>
        </>
      )}
    </RoundedSection>
  );
}
