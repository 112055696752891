import { useMutation, useQuery } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { z } from 'zod';

import { AddressFormInput } from '@oui/app-core/src/components/AddressFormInput';
import { AuthScreenContainer } from '@oui/app-core/src/components/AuthScreenContainer';
import { Button } from '@oui/app-core/src/components/Button';
import { View } from '@oui/app-core/src/components/View';
import {
  Controller,
  FormContainer,
  TextFormInput,
  TextInputRender,
  useZodForm,
} from '@oui/app-core/src/form';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { graphql } from '@oui/lib/src/graphql/tada';
import { MutationUpdateUserInputSchema } from '@oui/lib/src/types';

import { StackScreenProps } from '@src/types/navigation';

export const FinishPatientRegistrationQuery = graphql(`
  query FinishPatientRegistration {
    user {
      ID
      givenName
      familyName
      phone
      addresses {
        line
        city
        state
        postalCode
      }
    }
  }
`);

export const FinishPatientRegistrationMutation = graphql(`
  mutation FinishPatientRegistration($input: MutationUpdateUserInput!) {
    updateUser(input: $input) {
      ID
      givenName
      familyName
      phone
      addresses {
        line
        city
        state
        postalCode
      }
    }
  }
`);

const Schema = z.object({
  input: MutationUpdateUserInputSchema,
});

export function FinishPatientRegistration(_props: {}) {
  const { $t } = useI18n();
  const navigation = useNavigation<StackScreenProps<'FinishPatientRegistration'>['navigation']>();

  const { data } = useQuery(FinishPatientRegistrationQuery);

  const form = useZodForm(Schema, {
    defaultValues: {
      input: {
        userID: data?.user?.ID,
        user: {
          givenName: data?.user?.givenName,
          familyName: data?.user?.familyName,
          phone: data?.user?.phone,
          addresses: data?.user?.addresses.length
            ? [{ use: 'HOME', rank: 1, ...data.user.addresses[0] }]
            : undefined,
        },
      },
    },
  });

  const [update, { loading }] = useMutation(FinishPatientRegistrationMutation);

  return (
    <AuthScreenContainer
      heading={$t({ id: 'FinishPatientRegistration_title', defaultMessage: 'Finish registering' })}
      testID="FinishPatientRegistration"
    >
      <View
        style={{
          marginTop: 25,
          gap: 25,
        }}
      >
        {!data ? null : (
          <FormContainer {...form}>
            <TextFormInput control={form.control} name="input.user.givenName" label="First name" />
            <TextFormInput control={form.control} name="input.user.familyName" label="Last name" />
            <Controller
              control={form.control}
              render={(props) =>
                TextInputRender(props, {
                  autoComplete: 'tel',
                  inputMode: 'tel',
                  label: $t({
                    id: 'FinishPatientRegistration_phoneNumberLabel',
                    defaultMessage: 'Phone number',
                  }),
                })
              }
              name="input.user.phone"
            />
            <AddressFormInput control={form.control} name="input" requiresMailingAddress={false} />
            <Button
              alignSelf="center"
              testID="FinishPatientRegistration_submitButton"
              onPress={form.handleSubmit(async (data) => {
                const res = await update({ variables: data });
                if (res.data?.updateUser) {
                  navigation.navigate('Confidentiality');
                }
              })}
              disabled={loading}
              text={$t({ id: 'FinishPatientRegistration_submitButton', defaultMessage: 'Done' })}
            />
          </FormContainer>
        )}
      </View>
    </AuthScreenContainer>
  );
}
