import * as Sentry from '@sentry/core';
import ColorLib from 'color';
import _hexToRgba from 'hex-to-rgba';
import { ComponentProps, forwardRef, ReactNode, useLayoutEffect, useRef, useState } from 'react';
import {
  AccessibilityProps,
  GestureResponderEvent,
  Platform,
  Pressable,
  StyleProp,
  TextStyle,
  View,
} from 'react-native';
import { Pressable as GHPressable } from 'react-native-gesture-handler';
import Animated from 'react-native-reanimated';

import { useAccessibilityContext } from '../../components/AccessibilityContext';
import { ActivityIndicator } from '../../components/ActivityIndicator';
import { Icon } from '../../components/Icon';
import { Text } from '../../components/Text';
import { addBreadcrumb } from '../../lib/log';
import { isInteractionPaused } from '../../lib/pauseInteraction';
import { Theme, useTheme } from '../../styles';

const AnimatedPressable = Animated.createAnimatedComponent(Pressable);

type Props = Pick<
  AccessibilityProps,
  'aria-expanded' | 'accessibilityActions' | 'onAccessibilityAction'
> & {
  _useGestureHandler?: boolean;
  _useAnimated?: boolean;
  _accentColor?: string;
  alignSelf?: 'stretch' | 'center' | 'flex-start' | 'flex-end';
  children?: ReactNode;
  color?: string;
  /** a user has to perform an action or series of actions on the page in order to enable the button */
  disabled?: boolean;
  /** a user must wait for something else to happen outside their immediate control in order for the button to enable */
  unavailable?: boolean;
  hideIconOnLoading?: boolean;
  icon?: ComponentProps<typeof Icon>['name'];
  iconRight?: ComponentProps<typeof Icon>['name'];
  iconSize?: number;
  loading?: boolean;
  onPress: undefined | (() => unknown | Promise<unknown>);
  pressOnce?: boolean;
  skipOnPress?: boolean;
  style?:
    | ComponentProps<typeof Pressable>['style']
    | ComponentProps<typeof AnimatedPressable>['style'];
  testID?: string;
  text?: string;
  textStyle?: StyleProp<TextStyle>;
  size?: keyof Theme['button'];
  variant?: 'contained' | 'solid' | 'text';
  /**
   * @web - Submit sets type="submit" on the button for form integration
   */
  type?: 'submit' | 'button';
} & ({ text?: never; 'aria-label': string | undefined } | { text: string; 'aria-label'?: string });

export const Button = forwardRef<View, Props>(function Button(props, ref) {
  const { theme, Shadow } = useTheme();
  const pressedOnceRef = useRef(false);
  const [_loading, setLoading] = useState(false);
  const [pressedIn, setIsPressedIn] = useState(false);
  const loading = _loading || props.loading;
  const hasContent = !!(props.text || props.children);
  const accentColor = props._accentColor ?? theme.color.primary100;
  const isAccentColorDark = ColorLib(accentColor).darken(0.1).isDark();
  const size = props.size ?? 'normal';
  const variant = props.variant ?? 'contained';
  const color = props.unavailable
    ? variant === 'solid'
      ? 'white'
      : theme.color.gray500
    : props.color ||
      (variant === 'solid' ? (isAccentColorDark ? 'white' : theme.color.primary100) : accentColor);
  const { isScreenReaderEnabled } = useAccessibilityContext();
  const disabled = props.disabled || props.unavailable || loading;

  const isPressable = !(props._useGestureHandler && Platform.OS !== 'web');
  const Component = isPressable
    ? props._useAnimated
      ? AnimatedPressable
      : Pressable
    : GHPressable;
  const buttonTheme = theme.button[size];

  const lightened = ColorLib(theme.color.primary100).mix(ColorLib('white'), 0.9).hex();
  const darkened = ColorLib(theme.color.primary100).mix(ColorLib('black'), 0.2).hex();

  const iconSize = props.iconSize ?? buttonTheme.iconSize;
  const style: ComponentProps<typeof Pressable>['style'] = ({ pressed, hovered }) => {
    return [
      {
        backgroundColor: 'white',
        borderRadius: buttonTheme.borderRadius,
        paddingVertical: buttonTheme.paddingVertical,
        paddingHorizontal: hasContent ? buttonTheme.paddingHorizontal : buttonTheme.paddingVertical,
        borderWidth: 2,
        borderColor: accentColor,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: props.alignSelf,
      },
      variant === 'solid'
        ? [{ backgroundColor: accentColor }, isAccentColorDark ? undefined : Shadow.low]
        : null,
      variant === 'text'
        ? {
            borderColor: 'transparent',
            backgroundColor: 'transparent',
            alignSelf: props.alignSelf || 'flex-start',
            paddingHorizontal: 0,
          }
        : null,
      props.disabled ? { opacity: 0.5 } : null,
      props.unavailable
        ? variant === 'contained'
          ? { borderColor: theme.color.gray500 }
          : variant === 'solid'
            ? { backgroundColor: theme.color.gray600, borderColor: theme.color.gray600 }
            : null
        : null,
      typeof props.style === 'function'
        ? props.style({
            pressed,
            hovered,
          })
        : props.style,
      pressed && variant === 'solid' && !props.skipOnPress
        ? [
            {
              backgroundColor: isAccentColorDark ? darkened : lightened,
              borderColor: isAccentColorDark ? darkened : lightened,
            },
            Platform.OS === 'web' ? Shadow.low : null,
          ]
        : undefined,
      hovered && variant === 'solid' && !props.skipOnPress
        ? {
            backgroundColor: isAccentColorDark ? darkened : lightened,
            borderColor: isAccentColorDark ? darkened : lightened,
          }
        : undefined,
      hovered && variant === 'contained' && !props.skipOnPress
        ? { backgroundColor: lightened }
        : undefined,
      pressed && variant === 'contained' && !props.skipOnPress
        ? [{ backgroundColor: lightened }, Shadow.low]
        : undefined,
    ];
  };

  // if user doesn't provide a forwarded ref, we use our own
  const defaultRef = useRef<View>(null);
  let finalRef = ref || defaultRef;
  useLayoutEffect(() => {
    // @ts-expect-error
    if (finalRef?.current && props.type === 'submit') {
      // role="button" clobbers the type prop so we must apply it manually
      // @ts-expect-error
      finalRef.current.type = props.type;
    }
  }, [props.type, finalRef]);

  return (
    <Component
      aria-label={props['aria-label']}
      role="button"
      aria-disabled={!!(props.disabled || props.unavailable)}
      aria-expanded={props['aria-expanded']}
      accessibilityActions={props.accessibilityActions}
      onAccessibilityAction={props.onAccessibilityAction}
      aria-hidden={!(props['aria-label'] || props.text)}
      ref={finalRef}
      testID={props.testID}
      // disabled prop makes button invisible to iOS screen reader if true
      disabled={!isScreenReaderEnabled && disabled}
      onPressIn={() => setIsPressedIn(true)}
      onPressOut={() => setIsPressedIn(false)}
      onPress={(
        evt?:
          | GestureResponderEvent
          | Parameters<NonNullable<ComponentProps<typeof GHPressable>['onPress']>>[0],
      ) => {
        if (props.skipOnPress) return;
        if (disabled) return;
        if (isInteractionPaused()) return;
        addBreadcrumb({
          category: 'ui.click',
          message: props.testID ?? props.text,
          data: {
            componentType: 'Button',
            testID: props.testID,
            icon: props.icon,
            iconRight: props.iconRight,
            text: props.text,
          },
        });
        if (Platform.OS === 'web' && evt && 'preventDefault' in evt) {
          // Need to handle the following cases on web:
          // 1) Button inside Link (type is undefined) => preventDefault() to prevent double navigation
          // 2a) submit Button w/ onPress inside Form => preventDefault() to prevent double submit
          // 2b) submit Button w/o onPress inside Form => skip preventDefault so form is submitted
          if (props.type !== 'submit' || props.onPress) {
            evt.preventDefault();
          }
        }
        if (props.pressOnce && pressedOnceRef.current) return;
        pressedOnceRef.current = true;
        if (props.onPress) {
          const result = props.onPress();
          if (result === Promise.resolve(result)) {
            setLoading(true);
            // We handle error logic and don't want to swallow the original error
            void (result as Promise<unknown>)
              .catch((e) => e)
              .then((r) => {
                addBreadcrumb({
                  category: 'ui.click.async-done',
                  message:
                    props.testID ??
                    props.text ??
                    (typeof props.icon === 'string' ? props.icon : props.icon?.src),
                  data: {
                    error: r instanceof Error,
                  },
                });
                setLoading(false);
                pressedOnceRef.current = false;
                if (r instanceof Error) {
                  Sentry.captureException(r);
                  throw r;
                }
              });
          } else {
            pressedOnceRef.current = false;
          }
        }
      }}
      style={isPressable && !props._useAnimated ? style : style({ pressed: false })}
      // @ts-expect-error
      dataSet={{
        button: '',
        'button--text': variant === 'text' ? '' : undefined,
      }}
    >
      {loading ? <ActivityIndicator color={color} style={{ marginRight: 10 }} /> : null}
      {props.icon && (!loading || !props.hideIconOnLoading) ? (
        <Icon
          name={props.icon}
          color={color}
          size={iconSize || 20}
          style={hasContent ? { marginRight: 10 } : null}
        />
      ) : null}
      {props.text ? (
        <Text
          text={props.text}
          color={color}
          weight="semibold"
          textAlign={props.alignSelf === 'center' ? 'center' : undefined} // may always want center
          style={[props.textStyle, { lineHeight: buttonTheme.lineHeight }]}
          size={buttonTheme.fontSize}
        />
      ) : (
        props.children
      )}
      {props.iconRight && (!props.hideIconOnLoading || !loading) ? (
        <Icon
          name={props.iconRight}
          color={color}
          size={iconSize || 20}
          style={hasContent ? { marginLeft: 10 } : null}
        />
      ) : null}
      {pressedIn && variant === 'text' && Platform.OS !== 'web' ? (
        <View
          style={{
            height: 1,
            position: 'absolute',
            backgroundColor: color,
            right: 0,
            left: 0,
            bottom: 5,
          }}
        />
      ) : null}
    </Component>
  );
});
