import type { ApolloClient } from '@apollo/client';

import { graphql, ResultOf } from '@oui/lib/src/graphql/tada';
import { ContentType } from '@oui/lib/src/types/graphql.generated';

import { CurrentUserQuery, SelfPatientQuery } from '../hooks/useCurrentUser';

const FabricateStateV2EndMutation = graphql(`
  mutation FabricateStateV2End($content: ContentType!) {
    setOuiProgress(content: $content, value: 1) {
      content
      completion
      completed
      updatedAt
    }
  }
`);

export function mockConversationCompleteV2(client: ApolloClient<unknown>, convoID: ContentType) {
  return client.mutate({
    mutation: FabricateStateV2EndMutation,
    variables: { content: convoID },
    update: (cache, result) => {
      if (!result.data) return;
      const query = cache.readQuery({
        query: CurrentUserQuery,
      });
      const patientID = query?.user?.role?.ID;
      const key = cache.identify({ __typename: 'PatientProfile', patient: { ID: patientID } });
      cache.modify({
        id: key,
        fields: {
          ID: (id) => {
            return id;
          },
          // @ts-expect-error apollo types changed in 3.8.x and are no longer happy with us
          // specifying the type of existingProgress. At some point should circle back and
          // figure out how to type this properly
          progress: (
            existingProgress: NonNullable<
              NonNullable<ResultOf<typeof SelfPatientQuery>['user']>['role']
            >['progress'] = [],
          ) => {
            if (!result.data) return existingProgress;
            const progress = existingProgress.filter((p) => p.content !== convoID);
            return [...progress, result.data.setOuiProgress];
          },
        },
      });
    },
  });
}
