import { useNavigation } from '@react-navigation/native';
import addDays from 'date-fns/addDays';
import startOfWeek from 'date-fns/startOfWeek';
import { LinearGradient } from 'expo-linear-gradient';
import noop from 'lodash/noop';
import { memo } from 'react';

import { ActivityDiaryPracticeItem } from '@oui/activity-diary';
import { PracticeItem } from '@oui/app-core/src/components/PracticeItem';
import { Heading, Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { flatCard, useTheme } from '@oui/app-core/src/styles';
import { HopeKitPracticeItem } from '@oui/hope-kit';
import { parseGQLDateTime } from '@oui/lib/src/gqlDate';
import { FragmentOf, graphql, readFragment, ResultOf } from '@oui/lib/src/graphql/tada';
import { RelaxPracticeItem } from '@oui/relax-diary';
import { SleepDiaryPracticeItem, SleepDiaryPracticeItemFragment } from '@oui/sleep-diary';

import { AnimatedProgressCircle } from '@src/components/AnimatedProgressCircle';
import { Divider } from '@src/components/Divider';

export const DailyPracticeFragment = graphql(
  `
    fragment DailyPractice on Query {
      actionTodos {
        todo {
          __typename
          pendingActionID
          ... on PendingThoughtDiaryEntryTestAction {
            thoughtDiaryEntryPractice {
              practiceID
              practiceValues {
                date
              }
            }
          }
          ... on PendingThoughtDiaryEntrySwitchAction {
            thoughtDiaryEntryPractice {
              practiceID
              practiceValues {
                date
              }
            }
          }
          ... on PendingActivityRateAction {
            activityPractice {
              practiceID
              activity {
                title
              }
            }
          }
          ...SleepDiaryPracticeItem
        }
        completed {
          __typename
          actionID
          ... on ThoughtDiaryEntryTestAction {
            thoughtDiaryEntryPractice {
              practiceID
              practiceValues {
                date
              }
            }
          }
          ... on ThoughtDiaryEntrySwitchAction {
            thoughtDiaryEntryPractice {
              practiceID
              practiceValues {
                date
              }
            }
          }
          ... on ActivityRateAction {
            activityPractice {
              practiceID
              activity {
                title
              }
            }
          }
        }
      }
      actionProgresses(after: $progressAfter, before: $progressBefore) {
        date
        completed
        total
      }
    }
  `,
  [SleepDiaryPracticeItemFragment],
);

function ActionTodoItem(props: {
  item:
    | ResultOf<typeof DailyPracticeFragment>['actionTodos']['todo'][number]
    | ResultOf<typeof DailyPracticeFragment>['actionTodos']['completed'][number];
}) {
  const { theme } = useTheme();
  const { navigate } = useNavigation();
  const complete = !props.item.__typename.startsWith('Pending');
  const item = props.item;
  const { $t, formatDate } = useI18n();

  switch (item.__typename) {
    case 'PendingMyPlanReviewAction':
    case 'MyPlanReviewAction':
      return (
        <PracticeItem
          complete={complete}
          icon="my-plan"
          text={$t({ id: 'ActionTodoItem_myPlanReview', defaultMessage: 'Review using MyPlan' })}
          color={theme.color.accentThree100}
          onPress={() => navigate('MyPlanReview', {})}
        />
      );
    case 'PendingSleepDiaryEntryNightAction':
    case 'PendingSleepDiaryEntryMorningAction':
      return <SleepDiaryPracticeItem complete={complete} type={item.__typename} data={item} />;
    case 'SleepDiaryEntryNightAction':
    case 'SleepDiaryEntryMorningAction':
      return <SleepDiaryPracticeItem complete={complete} type={item.__typename} data={null} />;
    case 'PendingRelaxAction':
    case 'RelaxAction':
      return <RelaxPracticeItem complete={complete} />;
    case 'PendingThoughtDiaryEntrySwitchAction':
    case 'ThoughtDiaryEntrySwitchAction':
      return (
        <PracticeItem
          complete={complete}
          icon="spot-it"
          text={$t(
            {
              id: 'ActionTodoItem_thoughtDiarySwitch',
              defaultMessage: 'Switch your thought from {date}',
            },
            {
              date: formatDate(
                parseGQLDateTime(item.thoughtDiaryEntryPractice.practiceValues.date),
                {
                  month: 'short',
                  day: 'numeric',
                  weekday: 'short',
                },
              ),
            },
          )}
          color="#104f51"
          onPress={
            item.__typename === 'PendingThoughtDiaryEntrySwitchAction'
              ? () => {
                  navigate('ThoughtDiary', {});
                  navigate('ThoughtDiaryEntry', {
                    practiceID: item.thoughtDiaryEntryPractice.practiceID,
                  });
                }
              : noop
          }
        />
      );
    case 'PendingThoughtDiaryEntrySpotAction':
    case 'ThoughtDiaryEntrySpotAction':
      return (
        <PracticeItem
          complete={complete}
          icon="spot-it"
          text={$t({
            id: 'ActionTodoItem_thoughtDiarySpot',
            defaultMessage: 'Spot a negative thought',
          })}
          color="#104f51"
          onPress={() => {
            navigate('ThoughtDiary', {});
            navigate('NewThoughtDiaryEntry', { step: 'spot', cardStack: 'true' });
          }}
        />
      );
    case 'PendingThoughtDiaryEntryTestAction':
    case 'ThoughtDiaryEntryTestAction':
      return (
        <PracticeItem
          complete={complete}
          icon="spot-it"
          text={$t(
            {
              id: 'ActionTodoItem_thoughtDiaryTest',
              defaultMessage: 'Test your thought from {date}',
            },
            {
              date: formatDate(
                parseGQLDateTime(item.thoughtDiaryEntryPractice.practiceValues.date),
                {
                  month: 'short',
                  day: 'numeric',
                  weekday: 'short',
                },
              ),
            },
          )}
          color="#104f51"
          onPress={
            item.__typename === 'PendingThoughtDiaryEntryTestAction'
              ? () => {
                  navigate('ThoughtDiary', {});
                  navigate('ThoughtDiaryEntry', {
                    practiceID: item.thoughtDiaryEntryPractice.practiceID,
                  });
                }
              : noop
          }
        />
      );
    case 'PendingHopeKitAddAction':
    case 'HopeKitAddAction':
    case 'PendingHopeKitReviewAction':
    case 'HopeKitReviewAction':
      return <HopeKitPracticeItem type={item.__typename} complete={complete} />;
    case 'PendingCopingCardAddAction':
    case 'CopingCardAddAction':
      return (
        <PracticeItem
          complete={complete}
          icon="cards"
          text={$t({
            id: 'ActionTodoItem_copingCardAdd',
            defaultMessage: 'Add to your Coping cards',
          })}
          color="#2461c3"
          onPress={() => {
            navigate('CopingCards', {});
            navigate('EditCopingCards', {});
          }}
        />
      );
    case 'PendingCopingCardReviewAction':
    case 'CopingCardReviewAction':
      return (
        <PracticeItem
          complete={complete}
          icon="cards"
          text={$t({
            id: 'ActionTodoItem_copingCardReview',
            defaultMessage: 'Review your Coping cards',
          })}
          color="#2461c3"
          onPress={() => navigate('CopingCards', {})}
        />
      );
    case 'PendingActivityRateAction':
    case 'ActivityRateAction':
      return (
        <ActivityDiaryPracticeItem
          complete={complete}
          type={item.__typename}
          practice={item.activityPractice}
        />
      );
    case 'PendingActivityAddAction':
    case 'ActivityAddAction':
      return (
        <ActivityDiaryPracticeItem complete={complete} type={item.__typename} practice={null} />
      );
    case 'PendingBreathHoldingAction': // TODO
    case 'BreathHoldingAction': // TODO
    case 'EatingLogAddAction':
    case 'PendingHyperventilationAction': // TODO
    case 'HyperventilationAction': // TODO
    case 'PendingEatingLogAddAction':
    case 'PendingWordPairingAction': // TODO
    case 'WordPairingAction':
    case 'StaticAction':
      return null;
  }
}

function WeeklyProgress({
  progress,
}: {
  progress?: ResultOf<typeof DailyPracticeFragment>['actionProgresses'];
}) {
  const progressValues = progress?.length
    ? progress.map((p) => (p.total === 0 ? 0 : p.completed / p.total))
    : [0, 0, 0, 0, 0, 0, 0];
  const { formatDate } = useI18n();

  const start = startOfWeek(new Date());
  const dates = [0, 1, 2, 3, 4, 5, 6].map((i) => addDays(start, i));

  return (
    <View row style={{ justifyContent: 'space-between' }} testID="WeeklyProgress">
      {dates.map((date, i) => {
        const dateName = formatDate(date, { weekday: 'long' });
        return (
          <AnimatedProgressCircle
            key={dateName}
            testID={`WeeklyProgress_${i}`}
            text={dateName[0]}
            progress={progressValues[i]}
            aria-label={dateName}
          />
        );
      })}
    </View>
  );
}

const AllDoneRibbon = memo(() => {
  const { theme } = useTheme();
  const { $t } = useI18n();
  return (
    <LinearGradient
      colors={['rgba(236, 191, 45, 0)', 'rgba(236, 191, 45, 0.3)']}
      style={[{ marginRight: 30, height: 40, justifyContent: 'center', alignItems: 'center' }]}
      start={[0, 0]}
      end={[1, 0]}
    >
      <Text
        text={$t({ id: 'AllDoneRibbon', defaultMessage: 'All done for today!' })}
        testID="AllDoneRibbon"
        weight="semibold"
        color={theme.color.gray300}
      />
      <View
        style={{
          backgroundColor: 'white',
          position: 'absolute',
          transform: [{ rotateZ: '45deg' }],
          width: 40,
          height: 40,
          right: -30,
        }}
      />
    </LinearGradient>
  );
});

const FinishSession1Ribbon = memo(() => {
  const { theme } = useTheme();
  const { $t } = useI18n();
  return (
    <LinearGradient
      colors={['rgba(239, 239, 244, 0.5)', 'rgba(29, 103, 208, 0.10)', 'rgba(239, 239, 244, 0.5)']}
      style={[{ height: 40, justifyContent: 'center', alignItems: 'center' }]}
      locations={[0, 0.5, 1]}
      start={[0, 0]}
      end={[1, 0]}
    >
      <Text
        text={$t({
          id: 'FinishSession1Ribbon',
          defaultMessage: 'Finish session 1 to start practicing',
        })}
        testID="FinishSession1Ribbon"
        weight="semibold"
        color={theme.color.gray300}
      />
    </LinearGradient>
  );
});

/**
 * Renders practice data and  weekly progress
 */
export const DailyPractice = ({
  completedSession1,
  data: _data,
}: {
  completedSession1: boolean;
  data: FragmentOf<typeof DailyPracticeFragment> | null | undefined;
}) => {
  const data = readFragment(DailyPracticeFragment, _data);
  const { $t } = useI18n();
  const { theme } = useTheme();

  return (
    <View style={flatCard}>
      <Heading
        text={$t({ id: 'Home_dailyPracticeHeading', defaultMessage: 'Daily practice' })}
        testID="Home_dailyPracticeHeading"
        level={3}
        style={{ marginBottom: 25 }}
      />
      <Text
        text={$t({ id: 'Home_todoListHeading', defaultMessage: 'Up next' })}
        testID="Home_todoListHeading"
        color={theme.color.gray300}
        size={15}
        weight="semibold"
        role="heading"
        style={{ marginBottom: 20 }}
      />
      {!completedSession1 ? (
        <View style={{ marginHorizontal: -15 }}>
          <FinishSession1Ribbon />
        </View>
      ) : data?.actionTodos.todo.length === 0 ? (
        <AllDoneRibbon />
      ) : (
        <View testID="Home_dailyPracticeList">
          {data?.actionTodos.todo.map((todo, i, arr) => {
            return (
              <View key={todo.pendingActionID} testID={`Home_dailyPracticeList_item_${i}`}>
                <ActionTodoItem item={todo} />
                {i === arr.length - 1 ? null : <Divider />}
              </View>
            );
          })}
        </View>
      )}
      {data?.actionTodos.completed.length ? (
        <Text
          text="Done"
          size={15}
          color={theme.color.gray300}
          weight="semibold"
          role="heading"
          style={{ marginTop: 40, marginBottom: 20 }}
        />
      ) : null}
      {data?.actionTodos.completed.map((todo, i, arr) => {
        return (
          <View key={todo.actionID}>
            <ActionTodoItem item={todo} />
            {i === arr.length - 1 ? null : <Divider />}
          </View>
        );
      })}
      <View style={{ marginTop: 20 }}>
        <Text
          text={$t({
            id: 'Home_weeklyProgressHeading',
            defaultMessage: "This week's progress",
          })}
          size={15}
          testID="Home_weeklyProgressHeading"
          color={theme.color.gray300}
          weight="semibold"
          role="heading"
          style={{ marginBottom: 20 }}
        />
        <WeeklyProgress progress={data?.actionProgresses} />
      </View>
    </View>
  );
};
