import * as Sentry from '@sentry/core';

import { STATIC_SESSIONS, StaticSessionContent } from './content/session/static';
import { STATIC_SESSIONS as STATIC_SESSIONS_OTX207 } from './content/session/staticOTX207';
import { SUPPORT_CONTENT } from './content/session/support';
import { ContentType, ProductVariant, RoleType } from './types/graphql.generated';

// getStaticSession is a bit of a misnomer now. It used to refer to non-dynamic content written in
// markdown but since we've started using this style of content for the AVIVA_SUPPORT,productStatic=false
// users
export function getStaticSession({
  contentType,
  productSlug,
  roleType,
  productStatic,
}: {
  contentType: ContentType;
  productSlug: string;
  roleType: RoleType;
  productStatic: boolean;
}): StaticSessionContent {
  // console.log({ productSlug, roleType });
  if (productStatic) {
    let session = STATIC_SESSIONS[contentType];
    if (productSlug === ProductVariant.AVIVA_ADOLESCENT) {
      session = STATIC_SESSIONS_OTX207[contentType];
    }

    return typeof session === 'function' ? session(roleType) : session;
  } else if (productSlug === ProductVariant.AVIVA_ADOLESCENT && roleType === 'SUPPORTER') {
    return SUPPORT_CONTENT[contentType];
  }

  Sentry.captureMessage('Invalid call to getStaticSession', {
    extra: { contentType, productSlug, productStatic },
  });

  return [];
}
