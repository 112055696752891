"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    YstMentalHealthConcern: function() {
        return YstMentalHealthConcern;
    },
    YstMentalHealthConcernLabels: function() {
        return YstMentalHealthConcernLabels;
    },
    YstMentalHealthConcerns: function() {
        return YstMentalHealthConcerns;
    },
    YstMentalHealthGeneralResource: function() {
        return YstMentalHealthGeneralResource;
    },
    YstMentalHealthGeneralResourceLabels: function() {
        return YstMentalHealthGeneralResourceLabels;
    },
    YstTreatmentResponseLabel: function() {
        return YstTreatmentResponseLabel;
    }
});
var _zod = require("zod");
var YstMentalHealthGeneralResource = {
    TIPS_FOR_TALKING_TO_TEENS: 'TIPS_FOR_TALKING_TO_TEENS',
    RECOGNIZING_WARNING_SIGNS: 'RECOGNIZING_WARNING_SIGNS',
    TEEN_MENTAL_HEALTH: 'TEEN_MENTAL_HEALTH',
    TEEN_DEPRESSION: 'TEEN_DEPRESSION',
    CRISIS: 'CRISIS'
};
var YstMentalHealthGeneralResourceLabels = {
    TIPS_FOR_TALKING_TO_TEENS: 'Tips for talking with teens',
    RECOGNIZING_WARNING_SIGNS: 'Recognizing warning signs',
    TEEN_MENTAL_HEALTH: 'Teen mental health',
    TEEN_DEPRESSION: 'Teen depression',
    CRISIS: 'Emergency Resources'
};
var YstMentalHealthConcern = {
    SUICIDE_RISK: 'SUICIDE_RISK',
    DEPRESSION: 'DEPRESSION',
    BIPOLAR_DISORDER: 'BIPOLAR_DISORDER',
    ALCOHOL_ABUSE: 'ALCOHOL_ABUSE',
    DRUG_ABUSE: 'DRUG_ABUSE',
    SOCIAL_ANXIETY: 'SOCIAL_ANXIETY',
    GENERAL_ANXIETY: 'GENERAL_ANXIETY',
    PTSD: 'PTSD',
    ADHD: 'ADHD',
    EATING_DISORDER: 'EATING_DISORDER',
    SLEEP_PROBLEMS: 'SLEEP_PROBLEMS',
    CONDUCT_DISORDER: 'CONDUCT_DISORDER',
    AGGRESSION: 'AGGRESSION',
    GRIEF: 'GRIEF',
    BULLYING: 'BULLYING',
    TRAUMA: 'TRAUMA',
    DISCRIMINATION: 'DISCRIMINATION'
};
var YstMentalHealthConcerns = _zod.z.object({
    generalResources: _zod.z.record(_zod.z.nativeEnum(YstMentalHealthGeneralResource), _zod.z.coerce.boolean()).optional(),
    concerns: _zod.z.record(_zod.z.nativeEnum(YstMentalHealthConcern), _zod.z.boolean()),
    medication: _zod.z.object({
        status: _zod.z.enum([
            'YES',
            'NO',
            'SOON',
            'UNKNOWN'
        ]).nullish(),
        description: _zod.z.string()
    }),
    therapy: _zod.z.object({
        status: _zod.z.enum([
            'YES',
            'NO',
            'SOON',
            'UNKNOWN'
        ]).nullish(),
        description: _zod.z.string()
    }),
    treatmentResponse: _zod.z.union([
        _zod.z.number().int().min(0).max(4).nullish(),
        _zod.z.string().refine(function(value) {
            var numberValue = Number(value);
            return !Number.isNaN(numberValue) && Number.isInteger(numberValue) && numberValue >= 0 && numberValue <= 4;
        }, {
            message: 'treatmentResponse must be an integer between 0 and 4',
            path: [
                'treatmentResponse'
            ]
        }).transform(function(value) {
            return Number(value);
        })
    ])
}).default(function() {
    return {
        concerns: {},
        medication: {
            status: null,
            description: ''
        },
        therapy: {
            status: null,
            description: ''
        },
        treatmentResponse: null
    };
});
var YstMentalHealthConcernLabels = {
    SUICIDE_RISK: 'Suicide Risk',
    DEPRESSION: 'Depression',
    BIPOLAR_DISORDER: 'Bipolar Disorder',
    ALCOHOL_ABUSE: 'Alcohol Misuse',
    DRUG_ABUSE: 'Drug Misuse',
    SOCIAL_ANXIETY: 'Social Anxiety',
    GENERAL_ANXIETY: 'General Anxiety',
    PTSD: 'PTSD',
    ADHD: 'ADHD',
    EATING_DISORDER: 'Eating Disorders',
    SLEEP_PROBLEMS: 'Sleep Problems',
    CONDUCT_DISORDER: 'Conduct Disorder',
    AGGRESSION: 'Aggression',
    GRIEF: 'Coping with Grief',
    BULLYING: 'Dealing with Bullying',
    TRAUMA: 'Trauma',
    DISCRIMINATION: 'Discrimination'
};
var YstTreatmentResponseLabel = {
    0: 'Very poorly',
    1: 'Poorly',
    2: 'No change',
    3: 'Well',
    4: 'Very well'
};
