// Use a separate file from useCurrentUser.ts to prevent circular import with lib/auth.ts
import { graphql } from '@oui/lib/src/graphql/tada';

const CommonUserFragment = graphql(`
  fragment CommonUser on OuiUserType @_unmask {
    ... on OuiAdmin {
      ID
    }
    ... on Registrar {
      ID
    }
    ... on Practitioner {
      ID
    }
    ... on Patient {
      ID
    }
  }
`);

export const CurrentUserFragment = graphql(`
  fragment CurrentUser on User @_unmask {
    ID
    attributes {
      admin
      testUser
      trialAuditor
    }
    username
    createdAt
    email
    givenName
    familyName
    phone
    role {
      ID
      roleType
      organization {
        ID
        isTrialOrganization
      }
      product {
        ID
        slug
      }
      productStatic
      productConfig {
        ... on RoleAvivaConfig {
          onboardingVariant
          productVersion
        }
        ... on RolePtsdConfig {
          dosageVersion
        }
      }
    }
  }
`);

export const CurrentOuiUserFragment = graphql(
  `
    fragment CurrentOuiUser on CurrentOuiUser @_unmask {
      userID
      username
      createdAt
      attributes {
        admin
        testUser
        trialAuditor
      }
      role
      roles {
        ID
        role
        user {
          ...CommonUser
        }
        organization {
          ID
        }
      }
      primaryOrganization {
        ID
        isTrialOrganization
      }
      user {
        ... on OuiAdmin {
          ID
          person {
            email
            givenName
            familyName
            phone
          }
        }
        ... on Registrar {
          ID
          person {
            email
            givenName
            familyName
            phone
          }
        }
        ... on Practitioner {
          ID
          person {
            email
            givenName
            familyName
            phone
          }
        }
        ... on Patient {
          ID
          role {
            ID
            roleType
            product {
              ID
              slug
            }
          }
          productVersion
          productStatic
          onboardingVariant
          person {
            email
            givenName
            familyName
            phone
            birthDate
            race
            genderIdentity
            sexualOrientation
            militaryStatus
            address {
              use
              line
              city
              state
              postalCode
            }
          }
        }
      }
    }
  `,
  [CommonUserFragment],
);

export const CurrentUserQueryName = 'CurrentUser';
export type CurrentUserQueryName = typeof CurrentUserQueryName;
export const CurrentUserQuery = graphql(
  `
    query CurrentUser {
      user {
        ...CurrentUser
      }
    }
  `,
  [CurrentUserFragment],
);
