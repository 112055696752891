import { z } from 'zod';

export const YstMentalHealthGeneralResource = {
  TIPS_FOR_TALKING_TO_TEENS: 'TIPS_FOR_TALKING_TO_TEENS',
  RECOGNIZING_WARNING_SIGNS: 'RECOGNIZING_WARNING_SIGNS',
  TEEN_MENTAL_HEALTH: 'TEEN_MENTAL_HEALTH',
  TEEN_DEPRESSION: 'TEEN_DEPRESSION',
  CRISIS: 'CRISIS',
} as const;
export type YstMentalHealthGeneralResource =
  (typeof YstMentalHealthGeneralResource)[keyof typeof YstMentalHealthGeneralResource];

export const YstMentalHealthGeneralResourceLabels: {
  [K in YstMentalHealthGeneralResource]: string;
} = {
  TIPS_FOR_TALKING_TO_TEENS: 'Tips for talking with teens',
  RECOGNIZING_WARNING_SIGNS: 'Recognizing warning signs',
  TEEN_MENTAL_HEALTH: 'Teen mental health',
  TEEN_DEPRESSION: 'Teen depression',
  CRISIS: 'Emergency Resources',
} as const;

export const YstMentalHealthConcern = {
  SUICIDE_RISK: 'SUICIDE_RISK',
  DEPRESSION: 'DEPRESSION',
  BIPOLAR_DISORDER: 'BIPOLAR_DISORDER',
  ALCOHOL_ABUSE: 'ALCOHOL_ABUSE',
  DRUG_ABUSE: 'DRUG_ABUSE',
  SOCIAL_ANXIETY: 'SOCIAL_ANXIETY',
  GENERAL_ANXIETY: 'GENERAL_ANXIETY',
  PTSD: 'PTSD',
  ADHD: 'ADHD',
  EATING_DISORDER: 'EATING_DISORDER',
  SLEEP_PROBLEMS: 'SLEEP_PROBLEMS',
  CONDUCT_DISORDER: 'CONDUCT_DISORDER',
  AGGRESSION: 'AGGRESSION',
  GRIEF: 'GRIEF',
  BULLYING: 'BULLYING',
  TRAUMA: 'TRAUMA',
  DISCRIMINATION: 'DISCRIMINATION',
} as const;
export type YstMentalHealthConcern =
  (typeof YstMentalHealthConcern)[keyof typeof YstMentalHealthConcern];

export const YstMentalHealthConcerns = z
  .object({
    generalResources: z
      .record(z.nativeEnum(YstMentalHealthGeneralResource), z.coerce.boolean())
      .optional(), // This is only used for YST_SUPPORT patients
    concerns: z.record(z.nativeEnum(YstMentalHealthConcern), z.boolean()),
    medication: z.object({
      status: z.enum(['YES', 'NO', 'SOON', 'UNKNOWN']).nullish(),
      description: z.string(),
    }),
    therapy: z.object({
      status: z.enum(['YES', 'NO', 'SOON', 'UNKNOWN']).nullish(),
      description: z.string(),
    }),
    treatmentResponse: z.union([
      z.number().int().min(0).max(4).nullish(),
      z
        .string()
        .refine(
          (value) => {
            const numberValue = Number(value);
            return (
              !Number.isNaN(numberValue) &&
              Number.isInteger(numberValue) &&
              numberValue >= 0 &&
              numberValue <= 4
            );
          },
          {
            message: 'treatmentResponse must be an integer between 0 and 4',
            path: ['treatmentResponse'],
          },
        )
        .transform((value) => Number(value)),
    ]),
  })
  .default(() => ({
    concerns: {},
    medication: { status: null, description: '' },
    therapy: { status: null, description: '' },
    treatmentResponse: null,
  }));
export type YstMentalHealthConcerns = z.infer<typeof YstMentalHealthConcerns>;

export const YstMentalHealthConcernLabels: { [K in YstMentalHealthConcern]: string } = {
  SUICIDE_RISK: 'Suicide Risk',
  DEPRESSION: 'Depression',
  BIPOLAR_DISORDER: 'Bipolar Disorder',
  ALCOHOL_ABUSE: 'Alcohol Misuse',
  DRUG_ABUSE: 'Drug Misuse',
  SOCIAL_ANXIETY: 'Social Anxiety',
  GENERAL_ANXIETY: 'General Anxiety',
  PTSD: 'PTSD',
  ADHD: 'ADHD',
  EATING_DISORDER: 'Eating Disorders',
  SLEEP_PROBLEMS: 'Sleep Problems',
  CONDUCT_DISORDER: 'Conduct Disorder',
  AGGRESSION: 'Aggression',
  GRIEF: 'Coping with Grief',
  BULLYING: 'Dealing with Bullying',
  TRAUMA: 'Trauma',
  DISCRIMINATION: 'Discrimination',
} as const;

export const YstTreatmentResponseLabel = {
  0: 'Very poorly',
  1: 'Poorly',
  2: 'No change',
  3: 'Well',
  4: 'Very well',
};
